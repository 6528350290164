import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { TextInput } from "@mantine/core";

import { Message } from "./";

export default function MessageSearch({ reqData }) {
  const [results, setResults] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);

  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  useEffect(() => {
    if (!searchValue) return resetResults();
    fetchData();
  }, [searchValue]);

  function resetResults() {
    setResults([]);
  }

  function fetchData() {
    setLoading(true);

    const req = {
      ...reqData,
      search_value: searchValue,
      page_size: 100,
      page: 0,
      search_by: "message",
      blocked: false,
    };

    const cancel = cancelRef.current;
    if (cancel) {
      cancel();
    }

    axios
      .post(`/message-feed/`, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        setLoading(false);
        setResults(data.response[0].data);
      })
      .catch((err) => {
        setLoading(false);
        setResults([]);
      });
  }

  return (
    <>
      <StyledSearch>
        <TextInput
          placeholder="Start typing to search messages..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          size="md"
          autoFocus
        />
      </StyledSearch>
      {results.map((item, index) => (
        <div key={item.id}>
          <Message
            assets={item.assets}
            body={item.body}
            context={item.context}
            createdAt={item.created_at}
            favorited={item.favorited}
            id={item.id}
            incoming={item.incoming}
            isUser={false}
            message={item}
            senderName={item.from_display_name}
            stationInteractionId={item.station_interaction_id}
            linkPath={`/messaging-interactions/${
              item.user_location_id || item.user_organization_id
            }?entity_join_type=${
              item.user_location_id
                ? "user_location_id"
                : "user_organization_id"
            }`}
          />
        </div>
      ))}
    </>
  );
}

const StyledSearch = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 4;

  .mantine-TextInput-input {
    background: var(--mantine-color-dark-7);
    color: var(--mantine-color-dark-1);
    border-color: var(--mantine-color-dark-5);
  }
`;
