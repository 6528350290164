import React from "react";
import { Group, Text } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

import ViewTitle from "./ViewTitle";
import { iconSize } from "./helpers";
import { effortAwardType } from "@components/Effort/helpers";
import { getPlacementImage } from "@components/Asset/helpers";
import { placementDefs } from "@components/Asset/placementDefs";

export default function EffortRegistered({ registrationInfo, info }) {
  const { t } = useTranslation();

  if (!registrationInfo) return null;

  const image = info
    ? getPlacementImage(info.configuration, info.assets, placementDefs.featured)
    : null;

  return (
    <div>
      {image && (
        <Group justify="center" mb="sm">
          <img
            src={image}
            style={{
              width: "100%",
              maxHeight: "400px",
              objectFit: "contain",
            }}
          />
        </Group>
      )}
      <ViewTitle
        title={`${registrationInfo.name}`}
        image={image}
        subtitle={t("effort_already_registered")}
        icon={<IconCheck size={iconSize} />}
      />
      <Text size="xl" mb="xl" align="center" c="white">
        Completed {new Date(registrationInfo.date_submitted).toLocaleString()}
      </Text>
      <Text size="xl" mb="xl" align="center" c="white">
        {registrationInfo.award_type === effortAwardType.contest_entries
          ? t("non_recurring_registration_complete")
          : `${t("points")} ${t("gained")}: ${registrationInfo.award_value}`}
      </Text>
    </div>
  );
}
