import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Button, TextInput, Switch } from "@mantine/core";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import { DateField, TimeField } from "@components/shared";
import { CustomTooltip } from "@components/shared";
import { todayStart, dayStart, dayEnd } from "@util/dates";
import { initialState as initialKeywordSettings } from "@components/Keyword/KeywordSettings/helpers";
import { locationVariety } from "@components/Location/helpers";
import { keywordWizardSettings } from "./helpers";

export const defaultFormState = {
  allow_sms_claim: true,
  end_date: "",
  end_time: dayEnd,
  is_staff: false,
  keyword: "",
  lock_to_location: false,
  start_date: todayStart,
  start_time: dayStart,
};

export default function KeywordForm({
  additionalReqData = {},
  campaignId,
  contestId,
  editability = {
    keyword: true,
    start_date_and_time: true,
    end_date_and_time: true,
  },
  effortId,
  formInfo = defaultFormState,
  id,
  locationId,
  organizationId,
  minStartDate = null,
  maxEndDate = null,
  onSuccess,
  smsRequired = false,
  wizard = false,
}) {
  const [formData, setFormData] = useState(defaultFormState);
  const [keywordAvailable, setKeywordAvailable] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const managerInfo = useSelector((state) => state.manager);
  const isDealerLocation = !managerInfo
    ? false
    : managerInfo.location_variety === locationVariety.dealer;

  useEffect(() => {
    setFormData({
      ...formData,
      ...formInfo,
    });
  }, [JSON.stringify(formInfo)]);

  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  const getEntityInfo = () => {
    const req = {};

    if (effortId) req.campaign_effort_id = effortId;
    if (campaignId) req.campaign_id = campaignId;
    if (contestId) req.contest_id = contestId;
    if (locationId) req.location_id = locationId;
    if (organizationId) req.organization_id = organizationId;
    if (id) req.keyword_id = id;

    return req;
  };

  useEffect(() => {
    if (!formData.keyword) {
      setKeywordAvailable(true);
    }
    verifyKeyword();
  }, [JSON.stringify(formData)]);

  useEffect(() => {
    if (smsRequired) {
      setFormData({
        ...formData,
        allow_sms_claim: true,
      });
    }
  }, [smsRequired]);

  useEffect(() => {
    verifyKeyword();
  }, [organizationId, locationId, effortId, campaignId]);

  function verifyKeyword() {
    if (id) return;
    if (
      !formData.keyword ||
      !formData.start_date ||
      !formData.end_date ||
      !formData.start_time ||
      !formData.end_time
    )
      return;

    const cancel = cancelRef.current;
    if (cancel) {
      cancel();
    }

    const req = {
      ...additionalReqData,
      ...getEntityInfo(),
      ...formData,
    };

    axios
      .post(`/keyword-search/`, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        setKeywordAvailable(data.response[0].available);
      })
      .catch((err) => {
        setKeywordAvailable(false);
      });
  }

  function onSubmit() {
    if (!formData.keyword) return;

    if (id) return onUpdate();

    setSubmitting(true);

    const req = {
      ...getEntityInfo(),
      ...additionalReqData,
      ...formData,
    };

    if (!req.settings) req.settings = initialKeywordSettings;
    if (isDealerLocation) req.allow_sms_claim = false;

    // if hash is missing wizard settings, add them
    if (!req.wizard_settings) {
      req.wizard_settings = keywordWizardSettings;
    }

    if (wizard) {
      return onWizardSubmit(req);
    }

    axios
      .post(`/keywords/`, req)
      .then(({ data }) => {
        setSubmitting(false);
        toast.success("Keyword added!");
        setFormData(defaultFormState);
        onSuccess(data.response[0].id, formData.keyword);
      })
      .catch((err) => {
        toast.error(err);
        setSubmitting(false);
      });
  }

  function onWizardSubmit(reqData) {
    const req = {
      create_keyword: {
        ...reqData,
        create_effort: {
          ...reqData.create_effort,
          title: `${reqData.keyword} Points Keyword Effort`,
        },
      },
    };

    // if hash is missing wizard settings, add them
    if (!req.wizard_settings) {
      req.wizard_settings = keywordWizardSettings;
    }

    axios
      .post(`/wizard/`, req)
      .then(({ data }) => {
        // onSuccess(data.response[0].keyword_id);
        onSuccess(data.response[0].campaign_effort_id);
      })
      .catch((err) => {
        setSubmitting(false);
        toast.error(err);
      });
  }

  function onUpdate() {
    if (!formData.keyword) return;

    setSubmitting(true);

    const req = {
      ...getEntityInfo(),
      ...formData,
    };

    axios
      .put(`/keywords/${id}/`, req)
      .then(() => {
        setSubmitting(false);
        toast.success("Keyword updated!");
        onSuccess();
      })
      .catch((err) => {
        toast.error(err);
        setSubmitting(false);
      });
  }

  return (
    <div>
      {!id && (
        <TextInput
          value={formData.keyword}
          maxLength={100}
          onChange={(e) =>
            setFormData({ ...formData, keyword: e.target.value })
          }
          label="Keyword"
          error={
            id
              ? false
              : !keywordAvailable
              ? `${formData.keyword} is unavailable`
              : false
          }
          disabled={!editability.keyword || id ? true : false}
        />
      )}
      <DateField
        value={formData.start_date}
        onChange={(e) => setFormData({ ...formData, start_date: e })}
        label={`Start Date${minStartDate ? ` (min ${minStartDate})` : ""}`}
        min={minStartDate}
        disabled={!editability.start_date_and_time}
      />
      <TimeField
        value={formData.start_time}
        onChange={(e) => setFormData({ ...formData, start_time: e })}
        label="Start Time"
        disabled={!editability.start_date_and_time}
      />
      <DateField
        value={formData.end_date}
        onChange={(e) => setFormData({ ...formData, end_date: e })}
        label={`End Date${maxEndDate ? ` (max ${maxEndDate})` : ""}`}
        min={formData.start_date}
        max={maxEndDate}
        disabled={!editability.end_date_and_time}
      />
      <TimeField
        value={formData.end_time}
        onChange={(e) => setFormData({ ...formData, end_time: e })}
        label="End Time"
        disabled={!editability.end_date_and_time}
      />
      {id && (
        <Switch
          label={
            <CustomTooltip
              label="Staff Keyword: A user can successfully redeem only ONE staff keyword. Once they have redeemed a staff keyword for a given campaign effort, they won't be able to redeem another staff keyword.
            This is useful for internal staff competition to gauge which staff members are generating the most engagement."
            >
              Staff Keyword
            </CustomTooltip>
          }
          mt="xs"
          checked={formData.is_staff}
          onChange={(e) =>
            setFormData({
              ...formData,
              is_staff: !formData.is_staff,
            })
          }
        />
      )}
      {locationId && (
        <Switch
          label={
            <CustomTooltip label="Lock to Location: When toggled ON, the keyword can only be successfully redeemed at the Location (or any associated geospots) that created it. This requires the user to allow access to their device's location.">
              Lock to Location
            </CustomTooltip>
          }
          mt="xs"
          checked={formData.lock_to_location}
          onChange={(e) =>
            setFormData({
              ...formData,
              lock_to_location: !formData.lock_to_location,
            })
          }
        />
      )}
      {!isDealerLocation && !wizard && !smsRequired && (
        <Switch
          label={"Allow SMS claim"}
          disabled={id ? true : false}
          mt="xs"
          checked={formData.allow_sms_claim}
          onChange={(e) =>
            setFormData({
              ...formData,
              allow_sms_claim: !formData.allow_sms_claim,
            })
          }
        />
      )}
      <Button
        onClick={onSubmit}
        loading={submitting}
        mt="lg"
        fullWidth
        disabled={
          submitting ||
          !keywordAvailable ||
          !formData.keyword ||
          !formData.start_date ||
          !formData.end_date ||
          !formData.start_time ||
          !formData.end_time
        }
      >
        {wizard ? "Create Keyword" : `${id ? "Update" : "Add"} Keyword`}
      </Button>
    </div>
  );
}
