import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { NavLink, Link } from "react-router-dom";
import {
  Flex,
  UnstyledButton,
  Anchor,
  Group,
  useMantineTheme,
} from "@mantine/core";
import {
  IconStarFilled,
  IconSearch,
  IconMessage,
  IconCircleFilled,
} from "@tabler/icons-react";
import styled from "styled-components";

export default function ContactListHeader({ hasNewMessages }) {
  const locationSettings = useSelector((state) => state.locationSettings);
  const theme = useMantineTheme();

  return (
    <StyledHeader>
      <Flex gap="xl">
        <Link to="/messaging">
          <img
            style={{
              width: "50px",
              height: "50px",
              objectFit: "contain",
            }}
            width={90}
            // pos="center"
            src={
              locationSettings && locationSettings.logo_url
                ? locationSettings.logo_url
                : "https://mixer-public-assets.s3.amazonaws.com/logo-white.png"
            }
            alt="Logo"
          />
        </Link>
        <NavLink
          style={({ isActive }) => ({
            display: "flex",
            alignItems: "center",
            color: isActive
              ? "var(--mantine-color-gray-2)"
              : `var(--mantine-color-${theme.primaryColor}-6)`,
            textDecoration: "none",
            fontWeight: 600,
          })}
          to={`/messaging-feed`}
        >
          <IconMessage size={16} />{" "}
          <span style={{ marginLeft: "5px", marginRight: "10px" }}>
            Live Feed
          </span>
          <IconCircleFilled
            size={12}
            style={{
              color: hasNewMessages
                ? `var(--mantine-color-${theme.primaryColor}-6)`
                : `var(--mantine-color-dark-7)`,
            }}
          />
        </NavLink>
        <NavLink
          style={({ isActive }) => ({
            display: "flex",
            alignItems: "center",
            color: isActive
              ? "var(--mantine-color-gray-2)"
              : `var(--mantine-color-${theme.primaryColor}-6)`,
            textDecoration: "none",
            fontWeight: 600,
          })}
          to={`/messaging-favorites`}
        >
          <IconStarFilled size={16} />{" "}
          <span style={{ marginLeft: "5px" }}>Favorites</span>
        </NavLink>
        <NavLink
          style={({ isActive }) => ({
            display: "flex",
            alignItems: "center",
            color: isActive
              ? "var(--mantine-color-gray-2)"
              : `var(--mantine-color-${theme.primaryColor}-6)`,
            textDecoration: "none",
            fontWeight: 600,
          })}
          to={`/messaging-search`}
        >
          <IconSearch size={16} />{" "}
          <span style={{ marginLeft: "5px" }}>Search Messages</span>
        </NavLink>
      </Flex>
    </StyledHeader>
  );
}

const StyledHeader = styled.div`
  img {
    max-width: 100%;
    width: 200px;
    margin: 0 auto;
    display: block;
  }
`;
