import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Space,
  Divider,
  Card,
  Title,
  LoadingOverlay,
} from "@mantine/core";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

import { SongRating } from "@components/Song";
import { VuMeter } from "@components/DataViz";
import useInterval from "@util/useInterval";

export default function DashboardPlays() {
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  useInterval(() => {
    fetchData();
  }, 5000);

  function fetchData() {
    setLoading(true);

    axios
      .post(`/dashboard/`, {
        context: "play_data",
      })
      .then(({ data }) => {
        setLoading(false);
        setStats(data.response[0].play_data);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  if (loading && !stats)
    return (
      <Box
        pos="relative"
        mih={600}
        style={{
          backgroundColor: "var(--mantine-color-gray-1)",
          padding: "var(--mantine-spacing-xs)",
          borderRadius: "var(--mantine-radius-xl)",
          border: "1px solid var(--mantine-color-gray-2)",
        }}
      >
        <LoadingOverlay
          zIndex={1}
          visible
          overlayProps={{ radius: "sm", blur: 2 }}
        />
      </Box>
    );
  // if (!stats) return null;
  if (!stats && !loading) return <div>nada</div>;

  const playingNow = stats ? stats.find((f) => f.playing_now) : null;

  return (
    <>
      {!playingNow && (
        <>
          <Card
            mb="sm"
            p={0}
            style={{
              overflow: "hidden",
            }}
          >
            <Card.Section>
              <VuMeter
                leftValue={0}
                rightValue={0}
                leftTitle="Current Burn Rate"
                rightTitle="All-Time Burn Rate"
              />
            </Card.Section>
            <Box p="sm">
              <Title order={5} mb="sm">
                Nothing playing at the moment
              </Title>
            </Box>
          </Card>
        </>
      )}
      {playingNow && (
        <>
          <Title order={5} mb="sm">
            Now Playing
          </Title>
          <Card
            mb="sm"
            p={0}
            style={{
              overflow: "hidden",
            }}
          >
            <Card.Section>
              <VuMeter
                leftValue={playingNow.current_burn_rate || 0}
                rightValue={playingNow.all_time_burn_rate}
                leftTitle="Current Burn Rate"
                rightTitle="All-Time Burn Rate"
                // title={playingNow.song_name}
                // subtitle={playingNow.artist_name}
              />
            </Card.Section>
            <Box p="sm">
              <SongRating info={playingNow} />
            </Box>
          </Card>
        </>
      )}
      <Title order={5} mb="xs">
        Last played
      </Title>
      {stats
        .filter((f) => !f.playing_now)
        .map((p, i) => (
          <motion.div
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ duration: i * 0.15 }}
            key={i}
          >
            <Card mb="sm">
              <SongRating info={p} />
            </Card>
          </motion.div>
        ))}
    </>
  );
}
