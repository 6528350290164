import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  useParams,
  Link,
  Route,
  Routes,
  useSearchParams,
} from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Card,
  Group,
  Flex,
  Space,
  Anchor,
  Text,
  Switch,
  Box,
} from "@mantine/core";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import { ErrorView, PageTitle, Subnav } from "@components/shared";
import {
  KeywordDashboard,
  KeywordContestManagement,
  KeywordSettings,
  KeywordStatus,
  KeywordWizardStatus,
} from "@components/Keyword";
import { LocationAssociate } from "@components/Location";
import { KeywordForm } from "@components/Keyword";
import { TagManagement } from "@components/Tag";
import { defaultFormState } from "@components/Keyword/KeywordForm";

import entityIcon from "@util/entityIcon";
import entityColor from "@util/entityColor";

export default function KeywordDetail() {
  const queryClient = useQueryClient();
  // const [info, setInfo] = useState(null);
  // const [error, setError] = useState(null);
  const [showWizard, setShowWizard] = useState(true);
  const params = useParams();
  const { id } = params;
  const [searchParams] = useSearchParams();
  const queryKey = `keywords${id}`;

  const managerInfo = useSelector((state) => state.manager);

  const { failureReason: error, data: info } = useQuery({
    queryKey: [queryKey],
    queryFn: async () =>
      axios
        .get(`/keywords/${params.id}/`)
        .then(({ data }) => {
          const res = data.response[0];
          // setInfo(res);
          if (
            JSON.stringify(res.wizard_settings) === "{}" ||
            searchParams.get("quickSettings") === "false"
          ) {
            setShowWizard(false);
          }

          return res;
        })
        .catch((err) => {
          throw err;
        }),
  });

  const fetchData = () => queryClient.invalidateQueries([queryKey]);

  // function fetchData() {
  //   axios
  //     .get(`/keywords/${id}/`)
  //     .then(({ data }) => {
  //       const res = data.response[0];
  //       setInfo(res);
  //       if (JSON.stringify(res.wizard_settings) === "{}") {
  //         setShowWizard(false);
  //       }
  //     })
  //     .catch((err) => {
  //       setError(err);
  //       setInfo(null);
  //     });
  // }

  if (error) {
    return <ErrorView text={error} />;
  }

  if (!info) return null;

  const showLocations = false;
  // const showLocations =
  //   (!managerInfo || managerInfo.organization_id || info.organization_id) &&
  //   !info.location_id;

  const { wizard_settings: wizardSettings, wizard_status: wizardStatus } = info;
  const wizardComplete = wizardStatus.wizard_complete;
  const hasWizardSettings = JSON.stringify(wizardSettings) !== "{}";

  const WizardToggle = () => (
    <Switch
      label="Quick Settings"
      checked={showWizard}
      onChange={() => setShowWizard(!showWizard)}
      mb="lg"
    />
  );

  const subtitles = [
    {
      value: info.campaign_effort_id,
      icon: entityIcon.effort(),
      color: entityColor.effort,
      name: info.campaign_effort_name,
      url: `/efforts`,
    },
    {
      value: info.contest_info ? info.contest_info.id : null,
      icon: entityIcon.contest(),
      color: entityColor.contest,
      name: info.contest_info ? info.contest_info.name : null,
      url: `/contests`,
    },
    {
      value: info.campaign_id,
      icon: entityIcon.campaign(),
      color: entityColor.campaign,
      name: info.campaign_name,
      url: `/campaigns`,
    },
    {
      value: info.organization_id,
      icon: entityIcon.organization(),
      color: entityColor.organization,
      name: info.organization_name,
      url: `/organizations`,
    },
    {
      value: info.location_id,
      icon: entityIcon.location(),
      color: entityColor.location,
      name: info.location_name,
      url: `/locations`,
    },
  ]
    .filter((f) => f.value)
    .filter((f) => (managerInfo ? ["/efforts"].includes(f.url) : true))
    .map((m) => ({
      ...m,
      url: `${m.url}/${m.value}`,
    }));

  const KeywordTitle = () => (
    <React.Fragment>
      <PageTitle
        icon={entityIcon.keyword()}
        title={info.text}
        subtitle={<React.Fragment>Keyword {info.author_string}</React.Fragment>}
        iconColor={entityColor.keyword}
        style={{ marginBottom: "0" }}
      />
      <React.Fragment>
        {subtitles.map((m, i) => (
          <PageTitle
            key={i}
            icon={m.icon}
            iconColor={m.color}
            style={{ marginBottom: 0 }}
            subtitle={
              <Anchor
                style={{ color: "inherit" }}
                component={Link}
                to={m.url}
                target="_blank"
              >
                {m.name}
              </Anchor>
            }
          />
        ))}
      </React.Fragment>
      <Space mt="lg" />
    </React.Fragment>
  );

  if (info.status === 4) {
    return (
      <div>
        <PageTitle
          icon={entityIcon.keyword()}
          title={info.text}
          subtitle={
            <React.Fragment>
              Keyword {info.author_string}{" "}
              {info.campaign_effort_id && (
                <Anchor
                  component={Link}
                  to={`/efforts/${info.campaign_effort_id}/keywords`}
                >
                  for {info.campaign_effort_name} (Effort)
                </Anchor>
              )}
            </React.Fragment>
          }
          iconColor={entityColor.keyword}
        />
        <Text>This keyword has been deleted. There's nothing to do here.</Text>
      </div>
    );
  }

  if (!wizardComplete || showWizard) {
    return (
      <div>
        <KeywordTitle />
        {wizardComplete && hasWizardSettings && <WizardToggle />}
        <KeywordWizardStatus
          fetchData={fetchData}
          id={id}
          effortId={info.campaign_effort_id}
          locationId={info.location_id}
          organizationId={info.organization_id}
          status={info.status}
          wizardComplete={wizardComplete}
          wizardSettings={wizardSettings}
          wizardStatus={wizardStatus}
          keywordDates={
            info.start_date && info.end_date
              ? {
                  start_date: info.start_date,
                  start_time: info.start_time,
                  start_date_formatted: info.start_date_formatted,
                  end_date: info.end_date,
                  end_date_formatted: info.end_date_formatted,
                  end_time: info.end_time,
                }
              : null
          }
        />
      </div>
    );
  }

  return (
    <div>
      <KeywordTitle />
      {wizardComplete && hasWizardSettings && <WizardToggle />}
      <Flex>
        <Subnav
          buttonColor={entityColor.keyword}
          links={[
            { to: `/keywords/${id}`, text: "Detail" },
            { to: `/keywords/${id}/settings`, text: "Settings" },
            { to: `/keywords/${id}/contests`, text: "Contests" },
            { to: `/keywords/${id}/tags`, text: "Tags" },
            { to: `/keywords/${id}/locations`, text: "Locations" },
          ]
            .filter((f) =>
              // info.contest_info ||
              info.is_one_to_one ||
              (info.wizard_settings &&
                info.wizard_settings.show_contests === false)
                ? f.text !== "Contests"
                : true
            )
            .filter((f) =>
              !hasWizardSettings ? !["Settings"].includes(f.text) : true
            )
            .filter((f) => (!showLocations ? f.text !== "Locations" : true))}
        />
        {info.campaign_effort_id && info.organization_id && (
          <Button
            component={Link}
            variant="subtle"
            color="gray"
            size="xs"
            target="_blank"
            to={`/efforts/${info.campaign_effort_id}/locations`}
          >
            Locations
          </Button>
        )}
      </Flex>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <React.Fragment>
              <KeywordDashboard keywordData={info} />
              <Space mt="lg" />
              <KeywordStatus
                keywordId={id}
                status={info.status}
                fetchData={fetchData}
              />
              <Space mt="lg" />
              <Card>
                <KeywordForm
                  id={id}
                  editability={info.editability}
                  onSuccess={fetchData}
                  locationId={info.location_id}
                  formInfo={{
                    allow_sms_claim:
                      info.allow_sms_claim || defaultFormState.allow_sms_claim,
                    end_date: info.end_date || "",
                    end_time: info.end_time || defaultFormState.end_time,
                    keyword: info.text,
                    is_staff: info.is_staff || false,
                    lock_to_location: info.lock_to_location || false,
                    start_date: info.start_date || defaultFormState.start_date,
                    start_time: info.start_time || defaultFormState.start_time,
                  }}
                />
              </Card>
              {info.can_delete && (
                <React.Fragment>
                  <Space mt="xl" />
                  <DeleteButton id={id} fetchData={fetchData} />
                </React.Fragment>
              )}
            </React.Fragment>
          }
        />
        {(!info.is_one_to_one ||
          (info.wizard_settings &&
            info.wizard_settings.show_contests !== false)) && (
          <Route
            exact
            path="contests"
            element={
              <React.Fragment>
                {info.campaign_effort_id && (
                  <Box mb="sm">
                    <Anchor
                      fw={600}
                      c="orange"
                      component={Link}
                      to={`/efforts/${info.campaign_effort_id}/keywords?quickSettings=false`}
                    >
                      View {info.campaign_effort_name} keywords
                    </Anchor>
                  </Box>
                )}
                <KeywordContestManagement keywordId={id} />
              </React.Fragment>
            }
          />
        )}
        <Route
          exact
          path="tags"
          element={
            <React.Fragment>
              <TagManagement keywordId={id} />
            </React.Fragment>
          }
        />
        {hasWizardSettings && (
          <Route
            exact
            path="settings"
            element={
              <React.Fragment>
                <Card>
                  <KeywordSettings
                    keywordId={id}
                    beginDate={info.start_date}
                    endDate={info.end_date}
                  />
                </Card>
              </React.Fragment>
            }
          />
        )}
        {showLocations && (
          <Route
            exact
            path="locations"
            element={
              <React.Fragment>
                <LocationAssociate effortId={info.campaign_effort_id} />
              </React.Fragment>
            }
          />
        )}
      </Routes>
    </div>
  );
}

const DeleteButton = ({ id, fetchData }) => {
  const [loading, setLoading] = useState(false);

  function onClick() {
    setLoading(true);

    axios
      .post(`/keywords/${id}/delete/`, {})
      .then(() => {
        fetchData();
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  return (
    <Group>
      <Button
        size="xs"
        color="gray"
        variant="subtle"
        mt="xl"
        onClick={() => {
          if (window.confirm("Are you sure you want to delete this keyword?")) {
            onClick();
          }
        }}
        disabled={loading}
        loading={loading}
        leftSection={entityIcon.trash()}
      >
        Delete this keyword
      </Button>
    </Group>
  );
};
