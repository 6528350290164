import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { useIntersectionObserver } from "@uidotdev/usehooks";
import { UnstyledButton, Flex, ActionIcon, Text } from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import useInterval from "@util/useInterval";

import { Message } from "./";

const defaultPageSize = 25;

export default function MessageFeed({ contextFilter, onLoad, newestMessages }) {
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(0);
  const [vars, setVars] = useState({
    scroll_time_to_use: null,
    historic_time: new Date().valueOf(),
    next_time_to_use: null,
  });
  const [ref, entry] = useIntersectionObserver();
  const [newMessageAlert, setNewMessageAlert] = useState(false);
  const [respondedIds, setRespondedIds] = useState([]);

  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  const user = useSelector((state) => state.user);

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    setNewMessageAlert(false);
    onLoad();
  }, [entry?.isIntersecting]);

  useEffect(() => {
    fetchOldMessages();
  }, []);

  // useInterval(() => {
  //   fetchNewMessages();
  // }, 10000);

  function onNewClick() {
    if (!entry) return;
    document.getElementById("scrollTo").scrollIntoView({ behavior: "smooth" });
  }

  useEffect(() => {
    if (!newestMessages.length) return;

    setMessages(
      [
        ...messages,
        ...newestMessages.map((m) => ({
          ...m,
          context: contextFilter,
          sender_name: "",
        })),
      ]
        .filter(
          (obj1, i, arr) => arr.findIndex((obj2) => obj2.id === obj1.id) === i
        )
        .sort((a, b) =>
          new Date(a.created_at) > new Date(b.created_at) ? 1 : -1
        )
    );

    if (entry && !entry?.isIntersecting) {
      setNewMessageAlert(true);
    }
  }, [JSON.stringify(newestMessages)]);

  function fetchOldMessages() {
    setLoading(true);

    const req = {
      date_time: !vars.scroll_time_to_use
        ? vars.historic_time
        : vars.scroll_time_to_use,
      page_size: 25,
      page,
    };

    const cancel = cancelRef.current;
    if (cancel) {
      cancel();
    }

    axios
      .post(`/message-feed/`, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        const res = data.response[0];
        setHasMore(res.has_more);
        if (res.has_more) {
          setPage(page + 1);
          setVars({
            ...vars,
            scroll_time_to_use: new Date(res.data[0].created_at).valueOf(),
          });
        }

        setMessages(
          [
            ...messages,
            ...res.data.map((m) => ({
              ...m,
              context: contextFilter,
              sender_name: "",
            })),
          ].sort((a, b) =>
            new Date(a.created_at) > new Date(b.created_at) ? 1 : -1
          )
        );
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        // setMessages([]);
      });
  }

  function onMessageFavorite(messageId) {
    const newMessages = [...messages];
    const message = newMessages.find((f) => f.id === messageId);
    if (!message) return;
    message.favorited = !message.favorited;
    setMessages(newMessages);
  }

  return (
    <Wrapper>
      <div className="main__chat" id="mainChat">
        {newMessageAlert && (
          <NewMessageText>
            <Flex gap="xs">
              <Text
                component={UnstyledButton}
                onClick={onNewClick}
                fw={600}
                style={{ flexGrow: 1 }}
              >
                New Messages. Click to scroll.
              </Text>
              <ActionIcon
                onClick={() => setNewMessageAlert(false)}
                variant="subtle"
                color="#fff"
              >
                <IconX size={16} />
              </ActionIcon>
            </Flex>
          </NewMessageText>
        )}
        <InfiniteScroll
          dataLength={messages.length}
          next={() => {
            fetchOldMessages();
          }}
          hasMore={hasMore}
          loader={<p>Loading....</p>}
          // scrollThreshold={"20px"}
          scrollableTarget="mainChat"
          // initialScrollY={15}
          inverse
        >
          {messages.map((item, index) => (
            <div key={item.id}>
              <Message
                avatarUrl={item.avatar_url}
                assets={item.assets}
                body={item.body}
                context={"conversational"}
                createdAt={item.created_at}
                favorited={item.favorited}
                fromFeed
                id={item.id}
                incoming={item.incoming}
                isUser={user ? true : false}
                message={item}
                // key={item.id}
                onUpdate={(responded) => {
                  if (responded) {
                    setRespondedIds([...respondedIds, item.id]);
                  } else {
                    onMessageFavorite(item.id);
                  }
                }}
                refId={item.id}
                responded={respondedIds.includes(item.id)}
                senderName={item.from_display_name}
                stationInteractionId={item.station_interaction_id}
                linkPath={`/messaging-interactions/${
                  item.user_location_id || item.user_organization_id
                }?entity_join_type=${
                  item.user_location_id
                    ? "user_location_id"
                    : "user_organization_id"
                }`}
              />
            </div>
          ))}
          <div id="scrollTo" ref={ref} />
        </InfiniteScroll>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 2.5rem 5 3rem;
  height: 100%;
  position: relative;

  .main__chat {
    position: relative;
    height: ${(props) =>
      props.isUser
        ? `calc(100vh - ${props.isRating ? 22 : 24}rem)`
        : props.formVisible
        ? "calc(100vh - 13rem)"
        : "calc(100vh - 9rem)"};
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
  }
`;

const NewMessageText = styled.div`
  position: fixed;
  background: var(--mantine-color-red-7);
  top: 10px;
  /* bottom: 10; */
  width: 69%;
  padding: 10px;
  z-index: 9999;
  border-radius: 10px;
`;
