import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Box } from "@mantine/core";
import InfiniteScroll from "react-infinite-scroll-component";
import { shallow } from "zustand/shallow";

import { Contact } from "./";

import useAppStore from "./contact-list-store";

export default function ContactListBlocked() {
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [vars, setVars] = useState({
    scroll_time_to_use: null,
    historic_time: new Date().valueOf(),
    next_time_to_use: null,
    new_time: new Date().valueOf(),
  });
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({
    search_value: "",
    blocked: "true",
  });

  const { contacts, setContacts } = useAppStore(
    (state) => ({
      contacts: state.contacts,
      setContacts: state.setContacts,
    }),
    shallow
  );

  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  async function resetPagination() {
    try {
      setPage(0);
    } catch (error) {}
  }

  useEffect(() => {
    resetPagination().then(() => {
      fetchContacts(true);
    });
  }, [JSON.stringify(filters)]);

  function fetchContacts() {
    setLoading(true);

    const req = {
      ...filters,
      date_time: !vars.scroll_time_to_use
        ? vars.historic_time
        : vars.scroll_time_to_use,
      page_size: 25,
      page: page,
    };

    const cancel = cancelRef.current;
    if (cancel) cancel();

    axios
      .post(`/conversation-list-feed/blocked/`, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        const res = data.response[0];
        setHasMore(res.has_more);
        if (res.has_more) {
          setPage(page + 1);
          if (res.data.length > 0) {
            setVars({
              ...vars,
              scroll_time_to_use: new Date(res.data[0].last_message).valueOf(),
            });
          }
        }

        setContacts(
          formatContacts([
            ...contacts,
            ...res.data.map((m) => ({
              ...m,
              blocked: true,
              id: m.user_location_id || m.user_organization_id,
              join_id: m.user_location_id || m.user_organization_id,
              name: m.nickname || m.name || m.number || "Unidentified",
              description: m.last_message
                ? new Date(m.last_message).toLocaleString()
                : "",
            })),
          ])
        );

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  return (
    <Box p="sm">
      <InfiniteScroll
        dataLength={contacts.length}
        next={() => {
          fetchContacts();
        }}
        hasMore={hasMore}
        loader={<p>Loading...</p>}
        scrollableTarget="contactList"
      >
        {contacts.map((c) => (
          <Contact
            key={c.id}
            avatarUrl={c.avatar_url}
            name={c.name}
            muted={c.muted}
            flagged={c.flagged}
            blocked
            id={c.id}
            active={
              window.location.pathname ===
              `/messaging-interactions/${c.contact_list_id}`
            }
            description={c.description}
            fetchData={fetchContacts}
            userLocationId={c.user_location_id}
            userOrgId={c.user_organization_id}
            onBlockedToggle={() => fetchContacts(c.id)}
          />
        ))}
      </InfiniteScroll>
    </Box>
  );
}

function formatContacts(contacts) {
  return contacts;
  // return contacts.filter(
  //   (obj1, i, arr) => arr.findIndex((obj2) => obj2.id === obj1.id) === i
  // );
}
