import React, { useState } from "react";
import { Button, Modal } from "@mantine/core";

import WizardKeyword from "@components/InteractionWizard/WizardKeyword";

import entityColor from "@util/entityColor";
import entityIcon from "@util/entityIcon";

export default function KeywordModalCreate({
  effortId,
  onSuccess,
  locationId,
  organizationId,
  maxEndDate,
  buttonProps = {
    size: "sm",
  },
}) {
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
    onSuccess();
  }

  return (
    <div>
      <Button
        {...buttonProps}
        color={entityColor.keyword}
        leftSection={entityIcon.keyword(16)}
        onClick={() => setOpen(true)}
        radius="xl"
      >
        Create Keyword
      </Button>
      <Modal opened={isOpen} onClose={onClose}>
        <WizardKeyword
          selectedEffortId={effortId}
          locationId={locationId}
          organizationId={organizationId}
          maxEndDate={maxEndDate}
        />
      </Modal>
    </div>
  );
}
