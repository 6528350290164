import { create } from "zustand";

const useAppStore = create((set) => ({
  contactView: "pinned",
  contacts: [],
  setContacts: (contacts) => set(() => ({ contacts })),
  setContactView: (contactView) => set(() => ({ contactView, contacts: [] })),
}));

export default useAppStore;
