import React, { useState, useRef } from "react";
import axios from "axios";
import styled from "styled-components";
import {
  ActionIcon,
  Anchor,
  Avatar,
  TextInput,
  Badge,
  Flex,
  Text,
} from "@mantine/core";
import { Link } from "react-router-dom";
import { IconStarFilled, IconArrowBack } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const StyledMessage = styled.li`
  padding: 12px 4px;
  display: flex;
  flex-direction: column;
  font-size: 1.25em;
  border-radius: 4px;
  position: relative;

  &:nth-of-type(even) {
    /* background: #f9f9f9; */
  }

  time {
    display: block;
    margin-left: 5px;
    font-size: 0.65em;
    color: var(--textSecondaryColor);
  }

  .assets {
    margin-top: 0.5em;
  }
`;

const Message = ({
  assets,
  avatarUrl,
  body,
  context,
  createdAt,
  favorited = false,
  fromFeed,
  id,
  incoming,
  isUser = false,
  message,
  onUpdate,
  refId,
  responded,
  senderName,
  linkPath,
  stationInteractionId,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [value, setValue] = useState("");

  function onClose() {
    setOpen(false);
    setValue("");
  }

  function onFavoriteClick() {
    const req = {
      id,
    };

    axios
      .post(`/messages/${id}/toggle-favorite/`, req)
      .then(() => {
        onUpdate();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  function onSubmit(e) {
    e.preventDefault();
    if (value.trim().length === 0 || value.length > 255) return setValue("");

    const req = {
      message_text: value,
      context: "conversational",
    };

    if (message.user_location_id)
      req.user_location_id = message.user_location_id;
    if (message.user_organization_id)
      req.user_organization_id = message.user_organization_id;

    axios
      .post(`/send-convo-message/`, req)
      .then(() => {
        toast.success("Sent!");
        onClose();
        onUpdate(true);
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  return (
    <StyledMessage id={refId} favorited={favorited}>
      <Flex gap="lg" justify="flex-start">
        <Avatar src={avatarUrl} radius="xl" size="lg" />
        <div style={{ flexGrow: 1 }}>
          <Flex align="center" gap="sm">
            {linkPath ? (
              <Text size="md" fw={600}>
                <Anchor
                  component={Link}
                  to={linkPath}
                  style={{
                    color: "inherit",
                    textDecoration: "underline",
                  }}
                >
                  {senderName}
                </Anchor>
              </Text>
            ) : (
              <Text size="sm" fw={600}>
                {senderName}
              </Text>
            )}
            {createdAt && (
              <Text size="xs" c="dimmed">
                {new Date(createdAt).toLocaleString()}
                {!isUser && (
                  <React.Fragment>
                    {" "}
                    - {message.application_string}
                  </React.Fragment>
                )}
              </Text>
            )}
            {!isUser && context && context === "conversational" && (
              <Flex align="center">
                <ActionIcon
                  onClick={onFavoriteClick}
                  title={favorited ? "Unfavorite" : "Favorite"}
                  size="xs"
                  color={favorited ? "yellow" : "gray"}
                  variant="subtle"
                >
                  <IconStarFilled />
                </ActionIcon>
                {stationInteractionId && (
                  <Link to={`/interactions/${stationInteractionId}`}>View</Link>
                )}
              </Flex>
            )}
            {fromFeed && responded && (
              <Badge color="green" size="xs">
                Responded
              </Badge>
            )}
            {fromFeed && (
              <ActionIcon
                variant="subtle"
                p={0}
                onClick={() => {
                  setOpen(!isOpen);
                  setValue("");
                }}
              >
                <IconArrowBack size={16} />
              </ActionIcon>
            )}
          </Flex>
          {body && <Text size="lg">{body}</Text>}
          {assets.length > 0 && (
            <div className="assets">
              {assets.map((asset) => (
                <ImageItem key={asset.id} filename={asset.filename_url} />
              ))}
            </div>
          )}
          {isOpen && (
            <form onSubmit={onSubmit}>
              <StyledReply>
                <TextInput
                  autoFocus
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  variant="filled"
                  mt="xs"
                  placeholder="Start typing..."
                />
              </StyledReply>
            </form>
          )}
        </div>
      </Flex>
    </StyledMessage>
  );
};

const ImageItem = ({ filename }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <StyledImage>
      <img src={filename} onClick={() => setOpen(true)} />
      {/* <Modal onClose={() => setOpen(false)} open={isOpen}>
        <ModalImage src={filename} />
      </Modal> */}
    </StyledImage>
  );
};

const StyledImage = styled.div`
  & + & {
    margin-top: 1em;
  }

  img {
    cursor: pointer;
    max-width: 100%;
    display: block;
    max-height: 500px;
  }
`;

const ModalImage = styled.img`
  width: 100%;
  display: block;
`;

const StyledReply = styled.div`
  .mantine-TextInput-input {
    background: var(--mantine-color-dark-7);
    color: var(--mantine-color-dark-1);
  }
`;

export default Message;
