import React, { useState, useEffect } from "react";
import styled from "styled-components";
import toast, { Toaster } from "react-hot-toast";
import { connect, useSelector } from "react-redux";
import { shallow } from "zustand/shallow";
import {
  AppShell,
  Button,
  Image,
  Box,
  MantineProvider,
  useMantineTheme,
} from "@mantine/core";
import { Route, Routes, useNavigate } from "react-router-dom";
import axios from "axios";

import useAppStore from "@components/Messaging/contact-list-store";
import useInterval from "@util/useInterval";

import {
  ContactList,
  ContactListHeader,
  FavoriteMessages,
  MessageFeed,
  MessageSearch,
  MessagingNav,
} from "@components/Messaging";
import { InteractionDetail } from "@pages/Messaging";

const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  height: 100vh;
  background: var(--backgroundPrimaryColor);
  color: var(--textPrimaryColor);
  position: relative;
  /* padding: 1em; */

  main {
    padding: 1em 2.5em;
    position: relative;
  }

  aside {
    /* padding: 1em; */
    max-height: 100%;
    /* overflow-y: hidden; */
    background: var(--backgroundSecondaryColor);
    position: relative;
  }
`;

const LayoutAlt = styled.div`
  padding: 1em;
`;

const MessagingContainer = (props) => {
  const [refreshContacts, setRefreshContacts] = useState(false);
  const [hasNewMessages, setNewMessages] = useState(false);
  const [newestMessages, setNewestMessages] = useState([]);
  const navigate = useNavigate();
  const [vars, setVars] = useState({
    scroll_time_to_use: null,
    historic_time: new Date().valueOf(),
    next_time_to_use: null,
  });

  const userInfo = useSelector((state) => state.user);
  const settings = useSelector((state) => state.locationSettings);

  const { contacts, setContacts } = useAppStore(
    (state) => ({
      contacts: state.contacts,
      setContacts: state.setContacts,
    }),
    shallow
  );

  useInterval(() => {
    if (userInfo) return;
    fetchNewMessages();
  }, 8000);

  function fetchNewMessages() {
    const req = {
      // date_time: vars.historic_time,
      date_time: !vars.next_time_to_use
        ? vars.historic_time
        : vars.next_time_to_use,
    };

    axios
      .post(`/message-feed/new/`, req)
      .then(({ data }) => {
        if (data.response.length > 0) {
          const ids = data.response.map((m) => m.user_location_id);
          setNewMessages(true);
          setContacts(
            contacts.map((m) => ({
              ...m,
              flagged:
                window.location.pathname.indexOf(m.user_location_id) > -1
                  ? false
                  : ids.includes(m.user_location_id)
                  ? true
                  : m.flagged,
            }))
          );

          setNewestMessages(
            data.response
              .map((m) => ({
                ...m,
                context: "conversational",
                sender_name: "",
              }))
              .sort((a, b) =>
                new Date(a.created_at) > new Date(b.created_at) ? 1 : -1
              )
          );
        }
      })
      .then(() => {
        setVars({
          ...vars,
          next_time_to_use: new Date().valueOf(),
        });
        // setLoading(false);
      })
      .catch((err) => {
        // setLoading(false);
        // setMessages([]);
      });
  }

  function onContactLoad(id) {
    setContacts(
      contacts.map((m) => ({
        ...m,
        flagged: id === m.user_location_id ? false : m.flagged,
      }))
    );
  }

  if (!settings) return null;

  if (userInfo) {
    return (
      <LayoutAlt>
        <Toaster />
        <InteractionDetail
          onMessageSend={(e) => {
            true;
          }}
          onContactLoad={() => true}
          onLoad={() => true}
        />
      </LayoutAlt>
    );
  }

  return (
    <Wrapper>
      <Toaster />
      <AppShell
        header={{ height: 65 }}
        navbar={{
          width: 450,
          breakpoint: "sm",
        }}
        styles={(theme) => ({
          main: {
            background: "var(--mantine-color-dark-8)",
            color: "var(--textPrimaryColor)",
          },
        })}
      >
        <AppShell.Header
          height={65}
          style={{
            background: "var(--mantine-color-dark-9)",
            padding: "5px",
            borderColor: "var(--mantine-color-dark-7)",
          }}
        >
          <ContactListHeader hasNewMessages={hasNewMessages} />
        </AppShell.Header>
        <AppShell.Navbar
          style={{
            background: "var(--mantine-color-dark-8)",
            borderColor: "var(--mantine-color-dark-6)",
          }}
        >
          <StyledContactPane>
            <div className="list" id="contactList">
              <ContactList
                onDismissClick={(dismissedId) => {
                  if (
                    window.location.pathname ===
                    `/messaging/interactions/${dismissedId}`
                  ) {
                    navigate(`/`);
                  }
                }}
                refresh={refreshContacts}
                setNewMessages={setNewMessages}
              />
            </div>
          </StyledContactPane>
        </AppShell.Navbar>
        <AppShell.Main>
          <Box p="sm">
            <Routes>
              <Route path="/messaging" element={<MessagingNav />} />
              <Route
                path="/messaging-favorites"
                element={
                  <MainWrapper>
                    <div className="main__chat">
                      <FavoriteMessages />
                    </div>
                  </MainWrapper>
                }
              />
              <Route
                path="/messaging-feed"
                element={
                  <MainWrapper>
                    <div className="main__chat">
                      <MessageFeed
                        onLoad={() => {
                          setNewMessages(false);
                        }}
                        newestMessages={newestMessages}
                      />
                    </div>
                  </MainWrapper>
                }
              />
              <Route
                path="/messaging-search"
                element={
                  <MainWrapper>
                    <div className="main__chat">
                      <MessageSearch
                        reqData={{
                          date_limit: true,
                        }}
                      />
                    </div>
                  </MainWrapper>
                }
              />
              <Route
                path="/messaging-interactions/:id"
                element={
                  <>
                    <InteractionDetail
                      onMessageSend={(e) => {
                        // setRefreshContacts(!refreshContacts);
                      }}
                      onLoad={onContactLoad}
                    />
                  </>
                }
              />
            </Routes>
          </Box>
        </AppShell.Main>
      </AppShell>
      <div
        style={{
          position: "fixed",
          bottom: "10px",
          right: "10px",
        }}
      >
        <TogglePhoneService />
      </div>
    </Wrapper>
  );

  return (
    <Wrapper>
      <Layout>
        <Toaster />
        <aside>
          <ContactList
            onDismissClick={(dismissedId) => {
              if (
                window.location.pathname ===
                `/messaging/interactions/${dismissedId}`
              ) {
                navigate(`/`);
              }
            }}
            refresh={refreshContacts}
          />
        </aside>
        <main>
          <Routes>
            <Route path="/messaging" element={<MessagingNav />} />
            <Route
              path="/messaging-favorites"
              element={
                <MainWrapper>
                  <div className="main__chat">
                    <FavoriteMessages />
                  </div>
                </MainWrapper>
              }
            />
            <Route
              path="/messaging-feed"
              element={
                <MainWrapper>
                  <div className="main__chat">
                    <MessageFeed />
                  </div>
                </MainWrapper>
              }
            />
            <Route
              path="/messaging-search"
              element={
                <MainWrapper>
                  <div className="main__chat">
                    <MessageSearch
                      reqData={{
                        date_limit: true,
                      }}
                    />
                  </div>
                </MainWrapper>
              }
            />
            <Route
              path="/messaging-interactions/:id"
              element={
                <InteractionDetail
                  onMessageSend={(e) => {
                    // setRefreshContacts(!refreshContacts);
                  }}
                />
              }
            />
          </Routes>
        </main>
      </Layout>
      <div
        style={{
          position: "fixed",
          bottom: "10px",
          right: "10px",
        }}
      >
        <TogglePhoneService />
      </div>
    </Wrapper>
  );
};

function mapStateToProps(state) {
  return {};
}
export default connect(mapStateToProps)(MessagingContainer);

const TogglePhoneService = () => {
  function onClick() {
    axios
      .get(`/testing/toggle-sms/`)
      .then(({ data }) => {
        if (data.provider) {
          toast.success(`Now Using: ${data.provider}`.toLocaleUpperCase());
        }
      })
      .catch((err) => {});
  }

  return (
    <Button size="xs" variant="subtle" color="gray" onClick={onClick}>
      Toggle Twilio/Vonage
    </Button>
  );
};

const MainWrapper = styled.div`
  display: grid;
  grid-template-rows: 3rem 1fr 6rem;
  height: 100%;

  .main__channel {
    border-bottom: 2px solid var(--backgroundSecondaryColor);
  }

  .main__chat {
    height: calc(100vh - 5rem);
    overflow-y: auto;
    /* display: flex;
    justify-content: flex-end;
    align-items: flex-end; */
    /* padding: 1rem; */
  }

  .main__input {
  }
`;

const Wrapper = ({ children }) => {
  const settings = useSelector((state) => state.locationSettings);
  const theme = useMantineTheme();

  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{
        components: {
          Card: {
            defaultProps: {
              radius: "md",
              p: "sm",
              withBorder: true,
            },
          },
        },
        defaultGradient: {
          from: settings.color || "blue",
          to: "red",
          deg: 45,
        },
        fontFamily: theme.fontFamily,
        fontFamilyMonospace: theme.fontFamilyMonospace,
        headings: {
          ...theme.headings,
          fontFamily: theme.fontFamily,
        },
        // colorScheme: colorScheme,
        primaryColor:
          settings && settings.settings && settings.settings.settings
            ? settings.settings.settings.color
            : "blue",
        // primaryColor: colors ? colors.base : "orange",
      }}
    >
      {children}
    </MantineProvider>
  );
};

const StyledContactPane = styled.div`
  position: relative;
  top: 65px;

  .header {
    position: fixed;
    top: 65px;
    width: 449px;
    height: 250px;
    overflow: hidden;
    z-index: 4;
    border-bottom: 2px solid var(--mantine-color-dark-5);
  }

  .list {
    background: var(--mantine-color-dark-8);
    position: fixed;
    top: 65px;
    overflow-y: auto;
    height: calc(100vh - 4rem);
    width: 449px;
    /* position: fixed; */
    /* top: 22vh; */
    /* overflow-y: auto; */
    /* height: 80vh; */
    /* z-index: 3; */
  }
`;
