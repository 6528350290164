import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Card,
  Group,
  Text,
  Flex,
  TextInput,
  SegmentedControl,
} from "@mantine/core";
import { IconCircle, IconPencil } from "@tabler/icons-react";

import { DataFilters, PaginatedList } from "@components/shared";
import entityIcon from "@util/entityIcon";
import { KeywordStatusBadge } from "@components/Keyword";

const filterArr = [
  {
    text: "Status",
    key_name: "status",
    default_value: "entire",
    radio: true,
    options: [
      { text: "All", value: "entire" },
      { text: "In Use", value: "in_use" },
      { text: "Upcoming", value: "upcoming_queue" },
      { text: "Off", value: "off" },
      { text: "Expired", value: "past" },
      { text: "Waiting for activation", value: "waiting_for_activation" },
    ],
  },
].map((m, i) => ({
  ...m,
  all_values: m.options.map((mm) => mm.value),
  key: i + 1,
}));

const keywordStatus = {
  1: "indigo",
  2: "green",
  3: "red",
  4: "red",
};

const defaultFilters = filterArr.reduce((acc, cur) => {
  acc[cur.key_name] = cur.default_value;
  return acc;
}, {});

export default function KeywordList({
  items = null,
  requestInfo = {
    url: `/retrieve-keywords/`,
    data: {},
  },
  refresh,
}) {
  const [searchValue, setSearchValue] = useState("");
  const [filters, setFilters] = useState({
    ...defaultFilters,
    status: "entire",
  });

  const formattedFilters = Object.keys(filters).reduce((acc, cur) => {
    const obj = filterArr.find((f) => f.key_name === cur);
    acc[cur] = filters[cur].length ? filters[cur] : obj.all_values;
    return acc;
  }, {});

  const reqData = {
    ...formattedFilters,
    ...requestInfo.data,
    search_value: searchValue,
    refresh,
    pagination: true,
  };

  return (
    <div>
      {requestInfo && (
        <React.Fragment>
          <TextInput
            placeholder="Search..."
            size="lg"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            mb="sm"
          />
          <SegmentedControl
            value={filters.status}
            data={[
              { label: "All", value: "entire" },
              { label: "In Use", value: "in_use" },
              { label: "Upcoming", value: "upcoming_queue" },
              { label: "Off", value: "off" },
              { label: "Expired", value: "past" },
              {
                label: "Waiting for activation",
                value: "waiting_for_activation",
              },
            ]}
            onChange={(e) =>
              setFilters({
                ...filters,
                status: e,
              })
            }
          />
          {/* <DataFilters
            items={filterArr}
            radio
            values={filters}
            onChange={(key, val) =>
              setFilters({
                ...filters,
                [key]: val,
              })
            }
          /> */}
        </React.Fragment>
      )}
      <PaginatedList
        tableData={items}
        requestInfo={{
          url: requestInfo.url,
          data: reqData,
        }}
        responseMapping={(r) =>
          r.map((m) => ({
            ...m,
            link_url: `/keywords/${m.id}`,
          }))
        }
        displayFormatter={(item) => (
          <Card
            component={Link}
            to={item.original.link_url}
            key={item.original.id}
          >
            <Group>
              <Text fw={700}>{item.original.text}</Text>
              {item.original.status && (
                <IconCircle
                  size={16}
                  color={
                    item.original.status === 2
                      ? "var(--mantine-color-green-5)"
                      : "var(--mantine-color-red-5)"
                  }
                  fill={
                    item.original.status === 2
                      ? "var(--mantine-color-green-5)"
                      : "var(--mantine-color-red-5)"
                  }
                />
              )}
            </Group>
            {item.original.campaign_name && (
              <Group gap="xs">
                <div>{entityIcon.campaign()}</div>
                <Text size="sm">{item.original.campaign_name}</Text>
              </Group>
            )}
            {item.original.location_name && (
              <Group gap="xs">
                <div>{entityIcon.location()}</div>
                <Text size="sm">{item.original.location_name}</Text>
              </Group>
            )}
            {item.original.campaign_effort_name && (
              <Group gap="xs">
                <div>{entityIcon.effort()}</div>
                <Text size="sm">{item.original.campaign_effort_name}</Text>
              </Group>
            )}
            {item.original.start_date_formatted && (
              <Group gap="xs">
                <div>{entityIcon.calendar()}</div>
                <Text size="sm">
                  {item.original.start_date_formatted} -{" "}
                  {item.original.end_date_formatted}
                </Text>
              </Group>
            )}
            {/* {item.original.contest_count === 0 && (
              <Badge mt="sm" color="red">
                No contests!
              </Badge>
            )}
            {item.original.contest_count > 0 && (
              <Badge mt="sm" color="gray">
                {item.original.contest_count} Contest
                {item.original.contest_count > 1 ? "s" : ""}
              </Badge>
            )} */}
            {/* {item.original.status_formatted && (
              <Box mt="xs">
                <KeywordStatusBadge
                  status={item.original.status}
                  text={item.original.status_formatted}
                />
              </Box>
            )} */}
            {item.original.author_string && (
              <Group gap="xs" align="center">
                <div>
                  <IconPencil size={16} />
                </div>
                <Text size="sm">{item.original.author_string}</Text>
              </Group>
            )}
          </Card>
        )}
      />
    </div>
  );
}
