import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Button, Select, Box } from "@mantine/core";
import { useNavigate } from "react-router-dom";

import { ContestAddCreate } from "@components/Contest";

// link existing effort

export default function WizardAddContestToEffort({
  locationId,
  organizationId,
}) {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [effortId, setEffortId] = useState(false);
  const [effortName, setEffortName] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    fetchOptions();
  }, [searchValue]);

  function fetchOptions() {
    const cancel = cancelRef.current;
    if (cancel) cancel();

    const req = {
      engagement: "all",
      award: "all",
      status: "entire",
      variety: "sweepstakes",
      page: 0,
      page_size: 5,
      search_value: "",
      wizard_link_existing_efforts: true,
    };

    if (searchValue) req.search_value = searchValue;

    axios
      .post(`/retrieve-efforts/`, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        setOptions(
          data.response[0].data.map((d) => ({
            label: d.title,
            value: d.id,
          }))
        );
      })
      .catch((err) => {
        setOptions([]);
      });
  }

  function resetForm() {}

  return (
    <div>
      <Select
        data={options}
        label="Effort"
        limit={20}
        mb="xs"
        // onChange={(e) => setEffortId(e)}
        onChange={(e) => {
          setEffortId(e);
          if (e) {
            const foundOpt = options.find((f) => f.value === e);
            if (foundOpt) {
              setEffortName(foundOpt.label);
            }
          }
        }}
        onSearchChange={(e) => setSearchValue(e)}
        placeholder="Select one..."
        searchable
        searchValue={searchValue}
        value={effortId}
      />
      {effortId && (
        <Box mt="lg">
          <ContestAddCreate
            addInternal
            effortId={effortId}
            effortName={effortName}
            locationId={locationId}
            organizationId={organizationId}
            addOutside={organizationId ? true : false}
            redirect
            onSuccess={(createdContestId, linked = false) => {
              if (!linked) {
                navigate(`/contests/${createdContestId}`);
              } else {
                navigate(`/efforts/${effortId}`);
              }
            }}
          />
        </Box>
      )}
    </div>
  );
}
