import React, { useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Card,
  Group,
  SegmentedControl,
  Text,
  TextInput,
  Flex,
} from "@mantine/core";
import { IconCircle, IconPencil } from "@tabler/icons-react";
import toast from "react-hot-toast";

import { Countdown, DataFilters, PaginatedList } from "@components/shared";
import entityIcon from "@util/entityIcon";

function getFilters(isManager = false, isIndex = false) {
  return [
    {
      text: "Showing campaigns I'm",
      key_name: "context",
      default_value: "owned_participating",
      manager: isManager,
      radio: true,
      options: [
        { text: "managing", value: "owned_by" },
        {
          text: "participating in",
          value: "participating",
        },
        { text: "managing & participating in", value: "owned_participating" },
      ],
    },
    {
      text: "Status",
      key_name: "status",
      default_value: "entire",
      radio: true,
      options: [
        { text: "All", value: "entire" },
        { text: "Active", value: "active" },
        { text: "Upcoming", value: "upcoming" },
        { text: "Expired", value: "expired" },
      ],
    },
  ]
    .filter((f) => (isManager ? !f.manager : true))
    .filter((f) => (isIndex ? f.key_name !== "context" : true))
    .map((m, i) => ({
      ...m,
      all_values: m.options.map((mm) => mm.value),
      key: i + 1,
    }));
}

export default function CampaignList({
  hideFilters = [],
  isManager = false,
  isIndex = false,
  items = null,
  requestInfo = {
    url: `/retrieve-campaigns/`,
    data: {},
  },
  refresh,
}) {
  const params = useParams();
  const { id } = params;

  const filterArr = getFilters(isManager, isIndex);

  const defaultFilters = filterArr.reduce((acc, cur) => {
    if (cur.key_name === "status" && isManager) {
      acc.status = "entire";
    } else {
      acc[cur.key_name] = cur.default_value;
    }

    return acc;
  }, {});

  const [searchValue, setSearchValue] = useState("");
  const [filters, setFilters] = useState(defaultFilters);
  const [refresh2, setRefresh2] = useState(false);

  const formattedFilters = Object.keys(filters).reduce((acc, cur) => {
    const obj = filterArr.find((f) => f.key_name === cur);
    if (!obj) return;
    if (obj.radio) {
      acc[cur] = filters[cur];
    } else {
      acc[cur] = filters[cur].length ? filters[cur] : obj.all_values;
    }
    return acc;
  }, {});

  const reqData = {
    ...formattedFilters,
    ...requestInfo.data,
    search_value: searchValue,
    refresh,
    refresh2,
  };

  function onFilterChange(keyName, value) {
    if (typeof value === "object") {
      setFilters({
        ...filters,
        [keyName]: filters[keyName].includes(value)
          ? [...filters[keyName]].filter((f) => f !== value)
          : [...filters[keyName], value],
      });
    } else {
      setFilters({
        ...filters,
        [keyName]: value,
      });
    }
  }

  return (
    <div>
      {requestInfo && !hideFilters && (
        <React.Fragment>
          <TextInput
            placeholder="Search..."
            size="lg"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          {/* <DataFilters
            onChange={onFilterChange}
            values={filters}
            items={filterArr.filter((f) => !hiddenFilters.includes(f.key_name))}
          /> */}
          <Flex
            mt="sm"
            gap="sm"
            direction={{ base: "column", sm: "row" }}
            wrap="wrap"
          >
            <SegmentedControl
              value={filters.status}
              data={[
                { label: "All", value: "entire" },
                { label: "Active", value: "active" },
                { label: "Upcoming", value: "upcoming" },
                { label: "Expired", value: "expired" },
              ]}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  status: e,
                })
              }
            />
            {!isIndex && !isManager && (
              <SegmentedControl
                value={filters.context}
                data={[
                  {
                    label: "Managing & Participating",
                    value: "owned_participating",
                  },
                  { label: "Managing", value: "owned_by" },
                  { label: "Participating", value: "participating" },
                ]}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    context: e,
                  })
                }
              />
            )}
          </Flex>
        </React.Fragment>
      )}
      <PaginatedList
        tableData={items}
        requestInfo={{
          url: requestInfo.url,
          data: reqData,
        }}
        responseMapping={(r) =>
          r.map((m) => ({
            ...m,
            link_url: `/campaigns/${m.id}`,
          }))
        }
        displayFormatter={(item) => (
          <Card key={item.original.id}>
            <Flex gap="xs" align="center">
              <Text fw={700} component={Link} to={item.original.link_url}>
                {item.original.title}
              </Text>
              {item.original.status && (
                <IconCircle
                  size={16}
                  color={
                    item.original.status === 2
                      ? "var(--mantine-color-green-5)"
                      : "var(--mantine-color-red-5)"
                  }
                  fill={
                    item.original.status === 2
                      ? "var(--mantine-color-green-5)"
                      : "var(--mantine-color-red-5)"
                  }
                />
              )}
            </Flex>
            {!isManager && (
              <React.Fragment>
                {item.original.location_id && (
                  <Group gap="xs">
                    <div>{entityIcon.location()}</div>
                    <Text size="sm">{item.original.location_name}</Text>
                  </Group>
                )}
                {item.original.organization_id && (
                  <Group gap="xs">
                    <div>{entityIcon.organization()}</div>
                    <Text size="sm">{item.original.organization_name}</Text>
                  </Group>
                )}
              </React.Fragment>
            )}
            {item.original.start_date_formatted &&
              item.original.end_date_formatted && (
                <Group gap="xs" align="center">
                  <div>{entityIcon.calendar()}</div>
                  <Text size="sm">
                    {item.original.start_date_formatted} -{" "}
                    {item.original.end_date_formatted}
                  </Text>
                </Group>
              )}
            {item.original.end_date && (
              <Countdown
                endDate={item.original.end_date}
                startDate={item.original.start_date}
              />
            )}
            {item.original.active_effort_count > 0 && (
              <Group gap="xs" align="center">
                <div>{entityIcon.effort()}</div>
                <Text size="sm">
                  {item.original.active_effort_count} active effort
                  {item.original.active_effort_count === 1 ? "" : "s"}
                </Text>
              </Group>
            )}
            {item.original.author_string && (
              <Group gap="xs" align="center">
                <div>
                  <IconPencil size={16} />
                </div>
                <Text size="sm">{item.original.author_string}</Text>
              </Group>
            )}
            {item.original.children && (
              <Group>{item.original.children()}</Group>
            )}
            {requestInfo &&
              requestInfo.data &&
              !item.original.children &&
              ![
                item.original.organization_id,
                item.original.location_id,
              ].includes(id) && (
                <div>
                  <RemoveButton
                    reqData={{
                      ...requestInfo.data,
                      campaign_id: item.original.id,
                    }}
                    onSuccess={() => setRefresh2(!refresh2)}
                  />
                </div>
              )}
          </Card>
        )}
      />
    </div>
  );
}

const RemoveButton = ({ reqData, onSuccess }) => {
  const [loading, setLoading] = useState(false);

  function onClick() {
    const req = {
      ...reqData,
    };

    setLoading(true);

    axios
      .post(`/remove-participant-from-campaign/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Removed!");
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <Button
      mt="md"
      onClick={onClick}
      size="xs"
      variant="light"
      color="red"
      loading={loading}
      leftSection={entityIcon.trash()}
      radius="lg"
    >
      Remove
    </Button>
  );
};
