import React, { useState, useEffect } from "react";
import axios from "axios";
import { Title } from "@mantine/core";
import { CampaignList } from "@components/Campaign";
import { EffortList } from "@components/Effort";

export default function DashboardEfforts() {
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    axios
      .post(`/dashboard/`, {
        context: "effort_data",
      })
      .then(({ data }) => {
        setLoading(false);
        setStats(data.response[0]);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  if (!stats) return null;

  return (
    <>
      {stats?.active_campaigns.length > 0 && (
        <>
          <Title mb="sm" mt="lg" order={5}>
            Active Efforts
          </Title>
          <EffortList
            shouldFetch={false}
            showKeywordCreate
            showContestCreate
            items={stats.active_campaigns.map((m) => ({
              original: {
                ...m,
                link_url: `/efforts/${m.id}`,
              },
            }))}
          />
        </>
      )}
      {stats?.campaigns_starting_today.length > 0 && (
        <>
          <Title mb="sm" mt="lg" order={5}>
            Efforts starting today
          </Title>
          <EffortList
            shouldFetch={false}
            showKeywordCreate
            showContestCreate
            items={stats.campaigns_starting_today.map((m) => ({
              original: {
                ...m,
                link_url: `/efforts/${m.id}`,
              },
            }))}
          />
        </>
      )}
      {stats?.campaigns_ending_today.length > 0 && (
        <>
          <Title mb="sm" mt="lg" order={5}>
            Efforts ending today
          </Title>
          <EffortList
            shouldFetch={false}
            showKeywordCreate
            showContestCreate
            items={stats.campaigns_ending_today.map((m) => ({
              original: {
                ...m,
                link_url: `/efforts/${m.id}`,
              },
            }))}
          />
        </>
      )}
    </>
  );
}
