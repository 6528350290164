import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import styled from "styled-components";
import { Box, TextInput } from "@mantine/core";
import InfiniteScroll from "react-infinite-scroll-component";
import useInterval from "@util/useInterval";

import { shallow } from "zustand/shallow";

import { Contact } from "./";

import useAppStore from "./contact-list-store";

export default function ContactListSearch({ onDismissClick }) {
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [vars, setVars] = useState({
    scroll_time_to_use: null,
    historic_time: new Date().valueOf(),
    next_time_to_use: null,
    new_time: new Date().valueOf(),
  });
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({
    search_value: "",
    blocked: "false",
  });

  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  const { contacts, setContacts } = useAppStore(
    (state) => ({
      contacts: state.contacts,
      setContacts: state.setContacts,
    }),
    shallow
  );

  async function resetPagination() {
    try {
      setPage(0);
    } catch (error) {}
  }

  useEffect(() => {
    resetPagination().then(() => {
      fetchContacts(true);
    });
  }, [JSON.stringify(filters)]);

  function onNoSearchValue() {
    setPage(0);
    setHasMore(false);
    setContacts([]);
  }

  function fetchContacts() {
    setLoading(true);

    const url = !filters.search_value
      ? `/conversation-list-feed/retrieve-contacts/`
      : `/conversation-list-feed/search/`;

    const req = {
      ...filters,
      date_time: !vars.scroll_time_to_use
        ? vars.historic_time
        : vars.scroll_time_to_use,
      page_size: 25,
      page: page,
    };

    const cancel = cancelRef.current;
    if (cancel) cancel();

    axios
      .post(url, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        const res = data.response[0];
        setHasMore(res.has_more);
        if (res.has_more) {
          setPage(page + 1);
          if (res.data.length > 0) {
            setVars({
              ...vars,
              scroll_time_to_use: new Date(res.data[0].last_message).valueOf(),
            });
          }
        }

        if (!filters.search_value) {
          setContacts(
            formatContacts([
              ...contacts,
              ...res.data.map((m) => ({
                ...m,
                blocked: false,
                id: m.user_location_id || m.user_organization_id,
                join_id: m.user_location_id || m.user_organization_id,
                name: m.nickname || m.name || m.number || "Unidentified",
                description: m.last_message
                  ? new Date(m.last_message).toLocaleString()
                  : "",
              })),
            ])
          );
        } else {
          setContacts(
            formatContacts(
              res.data.map((m) => ({
                ...m,
                blocked: false,
                id: m.user_location_id || m.user_organization_id,
                join_id: m.user_location_id || m.user_organization_id,
                name: m.nickname || m.name || m.number || "Unidentified",
                description: m.last_message
                  ? new Date(m.last_message).toLocaleString()
                  : "",
              }))
            )
          );
        }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  return (
    <Box p="sm">
      <StyledSearch>
        <TextInput
          onChange={(e) =>
            setFilters({
              ...filters,
              search_value: e.target.value,
            })
          }
          value={filters.search_value}
          placeholder="Search users by phone, nickname, and name"
          radius="sm"
          size="md"
          style={{
            flexGrow: 1,
          }}
          mt="sm"
        />
      </StyledSearch>
      <InfiniteScroll
        dataLength={contacts.length}
        next={() => {
          fetchContacts();
        }}
        hasMore={hasMore}
        loader={<p>Loading...</p>}
        // scrollThreshold="1px"
        scrollableTarget="contactList"
      >
        {contacts.map((c) => (
          <Contact
            key={c.id}
            avatarUrl={c.avatar_url}
            name={c.name}
            muted={c.muted}
            flagged={c.flagged}
            blocked={c.blocked}
            pinned={c.pinned}
            id={c.id}
            active={
              window.location.pathname ===
              `/messaging-interactions/${c.contact_list_id}`
            }
            description={c.description}
            onDismiss={() => {
              fetchContacts();
              onDismissClick(c.id);
            }}
            rawData={c}
            fetchData={fetchContacts}
            userLocationId={c.user_location_id}
            userOrgId={c.user_organization_id}
          />
        ))}
      </InfiniteScroll>
    </Box>
  );
}

function formatContacts(contacts) {
  return contacts;
  // return contacts.filter(
  //   (obj1, i, arr) => arr.findIndex((obj2) => obj2.id === obj1.id) === i
  // );
  // .sort((a, b) =>
  //   new Date(a.last_message) < new Date(b.last_message) ? 1 : -1
  // );
}

const StyledSearch = styled.div`
  width: 100%;
  margin-bottom: 1em;
  .mantine-TextInput-input {
    background: var(--mantine-color-dark-7);
    color: var(--mantine-color-dark-1);
    border-color: var(--mantine-color-dark-5);
  }
`;
