import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Box,
  Text,
  Title,
  Flex,
  Grid,
  Loader,
  Card,
  ThemeIcon,
} from "@mantine/core";
import {
  IconBarbell,
  IconClipboardCheck,
  IconKey,
  IconMapPin,
  IconUser,
  IconStarFilled,
  IconMessage,
} from "@tabler/icons-react";
import { motion } from "framer-motion";

import { StatBlocks } from "@components/DataViz";
import entityColor from "@util/entityColor";

export default function DashboardMetaBlocks() {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setError(false);

    const req = {
      context: "meta_blocks",
    };

    axios
      .post(`/dashboard/`, req)
      .then(({ data }) => {
        setLoading(false);
        setItems(data.response[0].meta_blocks);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        setItems([]);
      });
  }

  if (loading || error) {
    return (
      <div style={{ minHeight: "100px" }}>
        <StatBlocks
          data={[
            error
              ? {
                  text: "There was a problem loading this data",
                  value: "Error!",
                }
              : { text: "Loading", value: <Loader size="sm" /> },
          ]}
        />
      </div>
    );
  }

  return (
    <Grid gutter="xs">
      {items.map((item, i) => (
        <Grid.Col key={i} span={{ base: 12, md: 4 }}>
          <motion.div
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ duration: 0.1 * i }}
          >
            <Card>
              <Flex align="center" gap="lg">
                {item.icon_key && icons[item.icon_key] !== undefined && (
                  <ThemeIcon
                    color={icons[item.icon_key].color}
                    size="xl"
                    radius="xl"
                    variant="light"
                  >
                    {icons[item.icon_key].icon()}
                  </ThemeIcon>
                )}
                <div style={{ flexGrow: 1 }}>
                  <Title order={2}>{item.value}</Title>
                  <Text size="xs">{item.text}</Text>
                </div>
              </Flex>
            </Card>
          </motion.div>
        </Grid.Col>
      ))}
    </Grid>
  );

  return (
    <Card>
      <Grid>
        {items.map((item, i) => (
          <Grid.Col key={i}>
            <Box></Box>
          </Grid.Col>
        ))}
      </Grid>
      <StatBlocks
        flex
        backgroundColor="var(--mantine-color-gray-0)"
        data={items}
      />
    </Card>
  );
}

const icons = {
  effort: {
    icon: () => <IconBarbell style={{ width: "70%", height: "70%" }} />,
    color: entityColor.effort,
  },
  user: {
    icon: () => <IconUser style={{ width: "70%", height: "70%" }} />,
    color: entityColor.user,
  },
  registration: {
    icon: () => <IconClipboardCheck style={{ width: "70%", height: "70%" }} />,
    color: "indigo",
  },
  checkin: {
    icon: () => <IconMapPin style={{ width: "70%", height: "70%" }} />,
    color: "lime",
  },
  keyword: {
    icon: () => <IconKey style={{ width: "70%", height: "70%" }} />,
    color: entityColor.keyword,
  },
  engagement: {
    icon: () => <IconMessage style={{ width: "70%", height: "70%" }} />,
    color: "red",
  },
  rating: {
    icon: () => <IconStarFilled style={{ width: "70%", height: "70%" }} />,
    color: entityColor.keyword,
  },
};
