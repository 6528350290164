import React from "react";
import { useSelector } from "react-redux";
import { Box, Text, Title, Flex, useMantineTheme } from "@mantine/core";
import { motion } from "framer-motion";

export default function DashboardEntityCallout() {
  const locationSettings = useSelector((state) => state.locationSettings);
  const managerInfo = useSelector((state) => state.manager);

  const activeLocation =
    managerInfo.location_id &&
    managerInfo.locations.find((f) => f.location_id === managerInfo.location_id)
      ? managerInfo.locations.find(
          (f) => f.location_id === managerInfo.location_id
        )
      : null;

  const activeOrg =
    managerInfo.organization_id &&
    managerInfo.organizations.find(
      (f) => f.organization_id === managerInfo.organization_id
    )
      ? managerInfo.organizations.find(
          (f) => f.organization_id === managerInfo.organization_id
        )
      : null;

  const activeEntity = activeLocation || activeOrg;

  if (!activeEntity || !locationSettings) return null;

  return (
    <Box mb="lg">
      <motion.div
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -10, opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Flex align="center" gap="lg">
          {locationSettings.logo_url && (
            <img
              style={{
                width: "150px",
                height: "150px",
                objectFit: "contain",
                display: "block",
                // background: "black",
              }}
              src={locationSettings.logo_url}
            />
          )}
          <div>
            <Title order={1} fw={600}>
              {getGreeting()}, {managerInfo.full_name.split(" ")[0]}!
            </Title>
            <Text>{activeEntity.name}</Text>
          </div>
        </Flex>
      </motion.div>
    </Box>
  );
}

function getGreeting() {
  var today = new Date();
  var curHr = today.getHours();

  if (curHr < 12) {
    return "Good morning";
  } else if (curHr < 18) {
    return "Good afternoon";
  } else {
    return "Good evening";
  }
}
