import React, { useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  Badge,
  Flex,
  Group,
  Text,
  Button,
  ThemeIcon,
} from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";
import { IconExclamationMark, IconPencil } from "@tabler/icons-react";

import { ContestIndicators } from "@components/Contest";
import { Countdown } from "@components/shared";
import entityIcon from "@util/entityIcon";

export default function ContestItem({
  campaignId,
  effortId,
  contestInfo = null,
  asLink = false,
  readOnly = false,
  children,
  fetchData,
  showIndicators = true,
}) {
  if (!contestInfo) return null;

  return (
    <Card>
      <Flex gap="xs">
        {contestInfo.featured_image_url && (
          <img
            style={{
              width: "100px",
              height: "125px",
              objectFit: "cover",
              borderRadius: "8px",
            }}
            src={contestInfo.featured_image_url}
          />
        )}
        <div style={{ flexGrow: 1 }}>
          <Flex gap="xs" align="center">
            <Text
              fw={700}
              component={asLink ? Link : null}
              to={contestInfo.link_url}
            >
              {contestInfo.name}
            </Text>
            {contestInfo.show_alert_flag && (
              <ThemeIcon
                radius="xl"
                color="red"
                size="xs"
                title="Alert"
                style={{ position: "relative", bottom: "2px" }}
              >
                <IconExclamationMark
                  style={{ width: "90%", height: "90%" }}
                  stroke={2.5}
                  title="Alert"
                />
              </ThemeIcon>
            )}
          </Flex>
          {contestInfo.variety_formatted && (
            <Group gap="xs" align="center">
              <div>{entityIcon.contest()}</div>
              <Text
                size="sm"
                style={{
                  textTransform: "capitalize",
                }}
              >
                {contestInfo.recurring_engagement === 1 ? "recurring " : ""}
                {contestInfo.variety_formatted}
              </Text>
            </Group>
          )}
          {contestInfo.start_date_formatted &&
            contestInfo.end_date_formatted && (
              <Group gap="xs">
                <div>{entityIcon.calendar()}</div>
                <Text size="sm">
                  {contestInfo.start_date_formatted} -{" "}
                  {contestInfo.end_date_formatted}
                </Text>
              </Group>
            )}
          {contestInfo.location_name && (
            <Group gap="xs">
              <div>{entityIcon.location()}</div>
              <Text size="sm">{contestInfo.location_name}</Text>
            </Group>
          )}
          {contestInfo.organization_name && (
            <Group gap="xs">
              <div>{entityIcon.organization()}</div>
              <Text size="sm">{contestInfo.organization_name}</Text>
            </Group>
          )}
          {contestInfo.created_in_name && (
            <Group gap="xs" align="center">
              <div>
                <IconPencil size={16} />
              </div>
              <Text size="sm">
                created in {contestInfo.created_in_name}{" "}
                {contestInfo.created_in_campaign_id && "(campaign)"}
                {contestInfo.created_in_campaign_effort_id && "(effort)"}
              </Text>
            </Group>
          )}
          {/* {contestInfo.variety === 1 && (
        <Flex align="center" gap="sm">
          <Group gap="xs">
            <Text size="sm">(X) Unique Entrants</Text>
          </Group>
          <Group gap="xs">
            <Text size="sm">(X) Member Entrants</Text>
          </Group>
          <Group gap="xs">
            <Text size="sm">(X) Non-Member Entrants</Text>
          </Group>
          <Group gap="xs">
            <Text size="sm">(X) Total Entries</Text>
          </Group>
        </Flex>
      )}
      {contestInfo.variety === 2 && (
        <Flex align="center" gap="sm">
          <Group gap="xs">
            <Text size="sm">(X) Unique Entrants</Text>
          </Group>
          <Group gap="xs">
            <Text size="sm">(X) Total Entries</Text>
          </Group>
        </Flex>
      )}
      {contestInfo.variety === 3 && (
        <Flex align="center" gap="sm">
          <Group gap="xs">
            <Text size="sm">(X) Unique Entrants</Text>
          </Group>
          <Group gap="xs">
            <Text size="sm">(X) Total Bids</Text>
          </Group>
        </Flex>
      )} */}
          {contestInfo.end_date && (
            <Countdown
              startDate={contestInfo.start_date}
              endDate={contestInfo.end_date}
            />
          )}
          {/* {showIndicators && (
        <ContestIndicators
          liveStatus={contestInfo.live_status}
          status={contestInfo.status}
        />
      )} */}
          {children && <Box mt="md">{children}</Box>}
          {!readOnly &&
            contestInfo.id &&
            (effortId || campaignId) &&
            effortId !== contestInfo.created_in_campaign_effort_id && (
              <ContestRemoveButton
                contestId={contestInfo.id}
                campaignId={campaignId}
                effortId={effortId}
                onSuccess={fetchData}
              />
            )}
        </div>
      </Flex>
    </Card>
  );
}

export const ContestRemoveButton = ({
  contestId,
  campaignId,
  effortId,
  onSuccess,
}) => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  if (!contestId) return null;

  function onClick() {
    const req = {
      contest_id: contestId,
    };

    if (campaignId) req.campaign_id = campaignId;
    if (effortId) req.campaign_effort_id = effortId;

    setLoading(true);

    axios
      .post(`/remove-participant-from-contest/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Removed!");
        // onSuccess();
        navigate(0);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <Button
      onClick={onClick}
      size="xs"
      variant="light"
      color="red"
      loading={loading}
      leftSection={entityIcon.trash()}
      radius="lg"
    >
      Remove
    </Button>
  );
};
