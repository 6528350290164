import React, { useState } from "react";
import { Button, Modal } from "@mantine/core";
import { useNavigate } from "react-router-dom";

import { ContestAddCreate } from "@components/Contest";

import entityColor from "@util/entityColor";
import entityIcon from "@util/entityIcon";

export default function ContestModalCreate({
  effortId,
  onSuccess,
  buttonProps = {
    size: "sm",
  },
  effortName,
  locationId,
  organizationId,
}) {
  const [isOpen, setOpen] = useState(false);

  const navigate = useNavigate();

  function onClose() {
    setOpen(false);
    onSuccess();
  }

  return (
    <div>
      <Button
        {...buttonProps}
        color={entityColor.contest}
        leftSection={entityIcon.contest(16)}
        onClick={() => setOpen(true)}
        radius="xl"
      >
        Add Contest
      </Button>
      <Modal opened={isOpen} onClose={onClose}>
        <ContestAddCreate
          effortId={effortId}
          effortName={effortName}
          organizationId={organizationId}
          locationId={locationId}
          wizardSetup
          onSuccess={(createdContestId, linked = false) => {
            if (!linked) {
              navigate(`/contests/${createdContestId}`);
            } else {
              navigate(`/efforts/${effortId}`);
            }
          }}
        />
      </Modal>
    </div>
  );
}
