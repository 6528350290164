import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, connect } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import {
  AppShell,
  Burger,
  Group,
  Text,
  Title,
  Box,
  ScrollArea,
  ThemeIcon,
  UnstyledButton,
  useMantineTheme,
} from "@mantine/core";
import { Toaster } from "react-hot-toast";
import { ErrorBoundary } from "react-error-boundary";

import entityColor from "@util/entityColor";
import entityIcon from "@util/entityIcon";
import { Nameplate, NavGroup, SuspenseWrapper } from "@components/shared";
import { ManagerSetSession } from "@components/Manager";
import { MessagingContainer } from "./";
import InteractionWizard from "@components/InteractionWizard";
import { locationVariety } from "@components/Location/helpers";

import { SET_LOCATION_SETTINGS } from "@duck/locationSettings";

import { LocationDetail, ManagerDetail } from "@pages/Admin";
import { Nav } from "@components/shared";

import classes from "./ManagerContainer.module.css";

function ManagerContainer({ locationSettings, dispatch }) {
  const loggedIn = useSelector((state) => state.manager) ? true : false;
  const managerInfo = useSelector((state) => state.manager);
  const avatarUrl = useSelector((state) => state.manager.avatar_url);
  const locationId = useSelector((state) => state.manager.location_id);
  const organizationId = useSelector((state) => state.manager.organization_id);
  const [opened, setOpened] = useState(false);

  const isStation =
    locationId && managerInfo.location_variety === locationVariety.station;

  const theme = useMantineTheme();
  const { pathname } = useLocation();

  useEffect(() => {
    fetchLocationSettings();
  }, []);

  function fetchLocationSettings() {
    if (!locationId) return;
    axios
      .get(`/locations/${locationId}/site-settings/`)
      .then(({ data }) => {
        dispatch({
          type: SET_LOCATION_SETTINGS,
          payload: data.response[0],
        });
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        //
      });
  }

  const navLinks = [
    {
      text: "Location Profile",
      to: `locations/${locationId}`,
      icon: entityIcon.location(),
      color: entityColor.location,
    },
  ];

  if (loggedIn && window.location.pathname.indexOf("messaging") > -1) {
    return <MessagingContainer />;
  }

  if (locationId && !locationSettings) return null;

  if (loggedIn) {
    return (
      <div>
        <AppShell
          padding="md"
          header={{ height: 65 }}
          navbar={{
            width: 250,
            breakpoint: "sm",
            collapsed: { mobile: !opened },
          }}
          styles={(theme) => ({
            main: {
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[8]
                  : theme.colors.gray[0],
            },
          })}
        >
          <AppShell.Header
            height={65}
            style={{
              background: "var(--mantine-color-dark-9)",
              padding: "5px",
            }}
          >
            <Group grow>
              <Group position="left">
                <div className={classes.burger}>
                  <Group>
                    <Burger
                      opened={opened}
                      onClick={() => setOpened(!opened)}
                      size="lg"
                      color={theme.colors.dark[1]}
                    />
                    <InteractionWizard />
                  </Group>
                </div>
                <div className={classes.logo}>
                  <Group align="center">
                    <img
                      src={
                        locationSettings && locationSettings.logo_url
                          ? locationSettings.logo_url
                          : "https://mixer-public-assets.s3.amazonaws.com/logo-white.png"
                      }
                      alt="Mixer"
                      style={{
                        width: "80px",
                        height: "50px",
                        objectFit: "contain",
                      }}
                    />
                  </Group>
                </div>
              </Group>
              <div className={classes.right}>
                <Group position="right" align="flexEnd" grow>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Nameplate
                      title={managerInfo.full_name}
                      avatarUrl={avatarUrl}
                    />
                  </div>
                </Group>
              </div>
            </Group>
          </AppShell.Header>
          <AppShell.Navbar pt="md" hidden={!opened} width={{ sm: 250 }}>
            <div component={ScrollArea} h="100vh" type="scroll" grow>
              <Nav
                links={navLinks.map((m) => ({
                  ...m,
                  to: `/${m.to}`,
                }))}
              />
              {isStation && (
                <UnstyledButton
                  component={"a"}
                  href={"/messaging-feed"}
                  target="_blank"
                  style={{
                    display: "block",
                    width: "100%",
                    padding: "var(--mantine-spacing-xs)",
                    backgroundColor: "transparent",
                    borderRadius: "var(--mantine-radius-sm)",
                    // "&:hover": {
                    //   backgroundColor:
                    //     theme.colorScheme === "dark"
                    //       ? theme.colors.dark[6]
                    //       : theme.colors.gray[0],
                    // },
                  }}
                >
                  <Group>
                    <ThemeIcon variant="light" color="gray">
                      {entityIcon.message()}
                    </ThemeIcon>
                    <Text size="sml">Messaging</Text>
                  </Group>
                </UnstyledButton>
              )}
              <UnstyledButton
                component={"a"}
                href={"/submit-trouble-ticket"}
                target="_blank"
                style={{
                  display: "block",
                  width: "100%",
                  padding: "var(--mantine-spacing-xs)",
                  backgroundColor: "transparent",
                  borderRadius: "var(--mantine-radius-sm)",
                  // "&:hover": {
                  //   backgroundColor:
                  //     theme.colorScheme === "dark"
                  //       ? theme.colors.dark[6]
                  //       : theme.colors.gray[0],
                  // },
                }}
              >
                <Group>
                  <ThemeIcon variant="light" color={entityColor.ticket}>
                    {entityIcon.ticket()}
                  </ThemeIcon>
                  <Text size="sml">Open support ticket</Text>
                </Group>
              </UnstyledButton>
            </div>
            <Box p="sm" mt="lg">
              <ManagerSetSession />
            </Box>
          </AppShell.Navbar>
          <AppShell.Main>
            <ErrorBoundary
              fallback={
                <div>
                  <Title>That's not good!</Title>
                  <Text>Something went wrong and we're going to fix it.</Text>
                </div>
              }
            >
              {(locationId || organizationId) && (
                <React.Fragment>
                  <SuspenseWrapper>
                    <div style={{ maxWidth: "1300px", margin: "0 auto" }}>
                      <Routes>
                        <Route
                          path="/profile"
                          element={<ManagerDetail isProfile />}
                        />
                        <Route
                          path="/locations/:id/*"
                          element={<LocationDetail isProfile />}
                        />
                      </Routes>
                    </div>
                  </SuspenseWrapper>
                </React.Fragment>
              )}
            </ErrorBoundary>
          </AppShell.Main>
        </AppShell>
        <Toaster />
      </div>
    );
  }

  return null;
}

function mapStateToProps(state) {
  return {
    locationSettings: state.locationSettings,
  };
}
export default connect(mapStateToProps)(ManagerContainer);
