import React from "react";
import axios from "axios";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  Avatar,
  Flex,
  Text,
  ActionIcon,
  Anchor,
  Box,
  Menu,
} from "@mantine/core";
import {
  IconBellOff,
  IconCircleFilled,
  IconBan,
  IconBell,
  IconX,
  IconPin,
  IconPinFilled,
  IconCircleCheck,
  IconDots,
} from "@tabler/icons-react";
import toast from "react-hot-toast";
import { shallow } from "zustand/shallow";

import useAppStore from "./contact-list-store";

const Contact = ({
  active,
  avatarUrl,
  blocked = false,
  description = "",
  fetchData,
  flagged = false,
  muted = false,
  name = "",
  pinned,
  rawData,
  userLocationId,
  userOrgId,
}) => {
  const linkPath = `/messaging-interactions/${
    userLocationId ? userLocationId : userOrgId
  }?entity_join_type=${
    userLocationId ? "user_location_id" : "user_organization_id"
  }`;

  const { contactView, contacts, setContacts } = useAppStore(
    (state) => ({
      contactView: state.contactView,
      contacts: state.contacts,
      setContacts: state.setContacts,
    }),
    shallow
  );

  const entityInfo = {
    user_location_id: userLocationId,
    user_organization_id: userOrgId,
  };

  function onBlockClick() {
    axios
      .post(`/member-toggle-block/`, entityInfo)
      .then(() => {
        setContacts(
          [...contacts].filter((f) => f.user_location_id !== userLocationId)
        );
      })
      .catch((err) => fetchData());
  }

  function onPinClick() {
    const req = {
      user_location_id: userLocationId,
    };

    axios
      .post(`/toggle-pinned-conversation/`, req)
      .then(() => {
        toast.success(pinned ? "Unpinned" : "Pinned");
        const newContacts = [...contacts];
        const item = newContacts.find(
          (f) => f.user_location_id === userLocationId
        );
        // is in list
        if (item) {
          if (pinned && contactView === "pinned") {
            setContacts(
              newContacts.filter((f) => f.user_location_id !== userLocationId)
            );
          } else {
            if (!pinned) {
              item.pinned = true;
              setContacts(newContacts);
            } else {
              item.pinned = false;
              setContacts(newContacts);
            }
          }
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  const currentlyViewingUser =
    window.location.pathname.indexOf(userLocationId) > -1;

  return (
    <StyledContact active={currentlyViewingUser}>
      <Flex gap="xs" align="center">
        <Anchor
          component={Link}
          to={linkPath}
          style={{
            flexGrow: 1,
            textDecoration: "none",
            "&:hover": {
              textDecoration: "none",
            },
          }}
        >
          <Flex
            gap="sm"
            align="center"
            style={{
              width: "100%",
            }}
          >
            {/* <Image width={50} height={50} radius="xl" src={avatarUrl} /> */}
            <Avatar src={avatarUrl} radius="xl" size="lg" />
            <Box style={{ flexGrow: 1 }}>
              <Flex gap="xs" align="center">
                <Text fw={600}>{name}</Text>
                {pinned && <IconPinFilled size={14} />}
              </Flex>
              {description && (
                <Text c="gray" size="xs">
                  {description}
                </Text>
              )}
            </Box>
            {flagged && (
              <div>
                <IconCircleFilled size={16} />
              </div>
            )}
          </Flex>
        </Anchor>
        {!currentlyViewingUser && (
          <Menu>
            <Menu.Target>
              <ActionIcon variant="subtle" color="gray">
                <IconDots size={16} />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              {!blocked && (
                <>
                  <Menu.Item
                    leftSection={<IconPin size={16} />}
                    onClick={onPinClick}
                  >
                    {pinned ? "Unpin" : "Pin"}
                  </Menu.Item>
                </>
              )}
              <Menu.Item
                leftSection={
                  blocked ? (
                    <IconCircleCheck size={16} />
                  ) : (
                    <IconBan size={16} />
                  )
                }
                onClick={onBlockClick}
              >
                {blocked ? "Unblock" : "Block"}
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        )}
      </Flex>
    </StyledContact>
  );
};

const StyledContact = styled.div`
  padding: 0.5em;
  border-radius: 10px;
  opacity: ${(props) => (props.muted ? 0.5 : 1)};
  transition: all 0.15s ease-in-out;
  background: ${(props) =>
    props.active ? "var(--mantine-color-dark-6)" : "transparent"};

  img {
    margin-bottom: 0;
  }
`;

const StyledSearch = styled.div`
  width: 100%;
  margin-bottom: 1em;
  .mantine-TextInput-input {
    background: var(--mantine-color-dark-7);
    color: var(--mantine-color-dark-1);
    border-color: var(--mantine-color-dark-5);
  }
`;

export default Contact;
