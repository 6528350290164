import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ActionIcon, Flex, Text } from "@mantine/core";
import {
  IconPencil,
  IconHome,
  IconBan,
  IconDeviceFloppy,
  IconArrowBack,
  IconSearch,
  IconPin,
  IconPinFilled,
} from "@tabler/icons-react";
import axios from "axios";
import toast from "react-hot-toast";

export default function Header({
  blocked,
  interactionId,
  name,
  nickname = "",
  phoneNumber,
  title,
  onUpdate,
  onFavoriteClick,
  onSearchToggle,
  onBlockedToggle,
  onPinnedToggle,
  pinned,
}) {
  const [isOpen, setOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setUsername(nickname);
  }, [nickname]);

  useEffect(() => {
    setOpen(false);
    setLoading(false);
  }, [title]);

  useEffect(() => {
    if (isOpen) {
      const el = document.getElementById("usernameEdit");
      if (el) {
        el.focus();
      }
    }
  }, [isOpen]);

  function onSaveClick() {
    const req = {
      station_interaction_id: interactionId,
      nickname: username,
    };

    setLoading(true);

    axios
      .post(`/station-interactions/${interactionId}/add-nickname/`, req)
      .then(() => {
        setOpen(false);
        setLoading(false);
        onUpdate();
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  function onPinClick() {
    const req = {
      user_location_id: interactionId,
    };

    axios
      .post(`/toggle-pinned-conversation/`, req)
      .then(() => {
        toast.success(pinned ? "Unpinned" : "Pinned");
        onUpdate();
        onPinnedToggle();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  function onBlockClick() {
    const req = {
      user_location_id: interactionId,
    };

    axios
      .post(`/member-toggle-block/`, req)
      .then(() => {
        toast.success(blocked ? "Unblocked" : "Blocked");
        onUpdate();
        onBlockedToggle();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  return (
    <Flex align="center">
      <Flex
        align="center"
        style={{
          flexGrow: 1,
        }}
        gap="sm"
      >
        {!isOpen && (
          <React.Fragment>
            <Text size="xl" fw={600}>
              {nickname || name || phoneNumber}
            </Text>
            {(nickname || name) && <Text c="dimmed">{phoneNumber}</Text>}
            <ActionIcon
              variant="subtle"
              onClick={() => setOpen(true)}
              title="Edit nickname"
            >
              <IconPencil />
            </ActionIcon>
            <ActionIcon
              variant="subtle"
              onClick={onSearchToggle}
              title="Search"
            >
              <IconSearch />
            </ActionIcon>
            <ActionIcon onClick={onPinClick} title="Pin" variant="subtle">
              {!pinned ? <IconPin /> : <IconPinFilled />}
            </ActionIcon>
            <ActionIcon onClick={onBlockClick} title="Block" variant="subtle">
              {!blocked ? (
                <IconBan
                  style={{
                    opacity: 0.1,
                  }}
                />
              ) : (
                <IconBan />
              )}
            </ActionIcon>
          </React.Fragment>
        )}
        {isOpen && (
          <Flex align="center">
            <StyledInput
              type="text"
              value={username}
              placeholder="Add a nickname"
              onChange={(e) => setUsername(e.target.value)}
              id="usernameEdit"
              disabled={loading}
            />
            <Flex gap="xs">
              <ActionIcon
                onClick={onSaveClick}
                title="Save"
                disabled={loading}
                loading={loading}
                variant="subtle"
              >
                <IconDeviceFloppy />
              </ActionIcon>
              <ActionIcon
                onClick={() => setOpen(false)}
                disabled={loading}
                title="Cancel"
                variant="subtle"
              >
                <IconArrowBack />
              </ActionIcon>
            </Flex>
          </Flex>
        )}
      </Flex>
      {/* <Flex align="center">
        <ActionIcon component={Link} to="/messaging" variant="subtle">
          <IconHome title="Home" />
        </ActionIcon>
      </Flex> */}
    </Flex>
  );
}

const StyledInput = styled.input`
  padding: 8px;
  font-size: 1.15em;
  border-radius: 5px;
  /* border: 2px solid #000; */
  border: none;
  color: var(--textThirdColor);
  background: var(--inputBackground);
  width: 250px;
  margin-right: 8px;
  font-family: inherit;

  &:focus {
    outline: none;
  }
`;
