import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Box, Flex, Button } from "@mantine/core";
import { shallow } from "zustand/shallow";

import useInterval from "@util/useInterval";

import { ContactListPinned, ContactListBlocked, ContactListSearch } from "./";

import useAppStore from "./contact-list-store";

const views = [
  {
    label: "Pinned",
    value: "pinned",
  },
  {
    label: "Search",
    value: "search",
  },
  {
    label: "Blocked",
    value: "blocked",
  },
];

export default function ContactList() {
  const { contactView, setContactView } = useAppStore(
    (state) => ({
      contactView: state.contactView,
      setContactView: state.setContactView,
    }),
    shallow
  );

  return (
    <Box p="sm">
      <Flex gap="xs" justify="center">
        {views.map((m, i) => (
          <Button
            size="xs"
            key={i}
            onClick={() => {
              if (m.value === contactView) return;
              setContactView(m.value);
            }}
            variant={contactView === m.value ? "light" : "subtle"}
          >
            {m.label}
          </Button>
        ))}
      </Flex>
      <div>
        {contactView === "search" && (
          <>
            <ContactListSearch />
          </>
        )}
        {contactView === "pinned" && (
          <>
            <ContactListPinned />
          </>
        )}
        {contactView === "blocked" && (
          <>
            <ContactListBlocked />
          </>
        )}
      </div>
    </Box>
  );
}
