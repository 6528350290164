import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import useInterval from "@util/useInterval";

import { MessageForm, Message } from "./";

const defaultPageSize = 25;

export default function Chat({
  contextFilter,
  interactionId,
  interactionInfo,
  onMessageSend,
  title = "",
}) {
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [hasMore, setHasMore] = useState(false);

  const [searchParams] = useSearchParams();

  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  const entityJoinType = searchParams.get("entity_join_type");

  const manager = useSelector((state) => state.manager);
  const user = useSelector((state) => state.user);

  const msgInput = document.getElementById("msgInput");

  const showForm = user ? true : contextFilter === "conversational";

  useEffect(() => {
    fetchMessages();
  }, [interactionId]);

  useEffect(() => {
    if (msgInput) {
      msgInput.focus();
    }
  }, [interactionId]);

  useInterval(() => {
    fetchMessages();
  }, 10000);

  useEffect(() => {
    fetchMessages(true);
  }, [contextFilter]);

  function fetchMessages(resetPageSize = false) {
    setLoading(true);

    const req = {
      context: contextFilter,
      page: 0,
      page_size: resetPageSize ? defaultPageSize : pageSize + defaultPageSize,
    };

    if (entityJoinType) req[entityJoinType] = interactionId;

    const cancel = cancelRef.current;
    if (cancel) {
      cancel();
    }

    axios
      .post(`/retrieve-member-messages/`, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        const res = data.response[0];
        setHasMore(res.has_more);

        if (resetPageSize) {
          setPageSize(defaultPageSize);
        } else {
          setPageSize(pageSize + defaultPageSize);
        }

        setMessages(
          res.data
            .map((m) => ({
              ...m,
              context: contextFilter,
              sender_name: m.incoming
                ? interactionInfo.nickname
                  ? interactionInfo.nickname
                  : "Them"
                : "Me",
            }))
            .sort((a, b) =>
              new Date(a.created_at) > new Date(b.created_at) ? 1 : -1
            )
        );
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setMessages([]);
      });
  }

  return (
    <Wrapper
      isRating={contextFilter === "rating"}
      isUser={user ? true : false}
      formVisible={showForm ? true : false}
    >
      <div className="main__chat" id="mainChat">
        <InfiniteScroll
          dataLength={messages.length}
          next={() => {
            fetchMessages();
          }}
          hasMore={hasMore}
          loader={<p>Loading....</p>}
          // scrollThreshold={"20px"}
          scrollableTarget="mainChat"
          // initialScrollY={15}
          inverse
        >
          {messages.map((item) => (
            <Message
              assets={item.assets}
              avatarUrl={item.avatar_url}
              body={item.body}
              context={item.context}
              createdAt={item.created_at}
              favorited={item.favorited}
              id={item.id}
              incoming={item.incoming}
              isUser={user ? true : false}
              message={item}
              key={item.id}
              onUpdate={fetchMessages}
              refId={item.id}
              senderName={item.from_display_name}
              stationInteractionId={item.station_interaction_id}
            />
          ))}
        </InfiniteScroll>
      </div>
      {showForm && (
        <div className="main__input">
          <MessageForm
            contextFilter={contextFilter}
            joinId={interactionId}
            title={title}
            onSuccess={() => {
              fetchMessages();
              onMessageSend();
            }}
          />
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 2.5rem 5 3rem;
  height: ${(props) => (props.isUser ? "20%" : "100%")};

  .main__channel {
    border-bottom: 2px solid var(--backgroundSecondaryColor);
  }

  .main__chat {
    height: ${(props) =>
      props.isUser
        ? `calc(95vh - ${props.isRating ? 22 : 24}rem)`
        : props.formVisible
        ? "calc(95vh - 10rem)"
        : "calc(95vh - 9rem)"};
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
    /* display: flex;
    justify-content: flex-end;
    align-items: flex-end; */
    /* padding: 1rem; */
  }

  .main__input {
    width: 100%;
  }
`;
