import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Text, UnstyledButton, Group, ThemeIcon } from "@mantine/core";

export default function Nav({ links, isAdmin }) {
  return (
    <React.Fragment>
      {links.map((l, i) => (
        <CustomLink
          key={i}
          to={l.to}
          text={l.text}
          icon={l.icon}
          color={l.color}
        />
      ))}
    </React.Fragment>
  );
}

export const CustomLink = ({ to, color = "blue", icon, text }) => {
  const location = useLocation();

  return (
    <NavLink
      component={NavLink}
      to={to}
      style={({ isActive }) => {
        return {
          color: "initial",
          display: "block",
          width: "100%",
          padding: "var(--mantine-spacing-xs)",
          textDecoration: "none",
          backgroundColor: isActive
            ? `var(--mantine-color-${color}-0)`
            : "transparent",
          borderRadius: "var(--mantine-radius-sm)",
          "&:hover": {
            backgroundColor: "var(--mantine-color-dark-6)",
          },
        };
      }}
    >
      <Group>
        <ThemeIcon variant="light" color={color}>
          {icon}
        </ThemeIcon>
        <Text size="sml">{text}</Text>
      </Group>
    </NavLink>
  );
};
