import React, { useState, useEffect, useRef } from "react";
import { Box, Button, Divider, Flex } from "@mantine/core";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { shallow } from "zustand/shallow";

import { ChatHeader, Chat, MessageSearch } from "@components/Messaging";

import useAppStore from "@components/Messaging/contact-list-store";

const defaultPageSize = 10;

const contextFilters = [
  { text: "Conversation", value: "conversational" },
  { text: "Keyword", value: "keyword" },
  { text: "Rating", value: "rating" },
];

export default function InteractionDetail({ onMessageSend, onLoad }) {
  const [interactionInfo, setInteraction] = useState(null);
  const [filters, setFilters] = useState({
    context: "conversational",
    favorites: false,
  });
  const [searching, setSearching] = useState(false);
  const { id } = useParams();

  const manager = useSelector((state) => state.manager);
  const user = useSelector((state) => state.user);

  const { contactView, contacts, setContacts } = useAppStore(
    (state) => ({
      contactView: state.contactView,
      contacts: state.contacts,
      setContacts: state.setContacts,
    }),
    shallow
  );

  useEffect(() => {
    fetchData();
    onLoad(id);
  }, [id]);

  function fetchData() {
    if (!id) return;

    axios
      .get(`/station-interactions/${id}/`)
      .then(({ data }) => {
        setInteraction(data.response[0]);
        // onLoad(id);
      })
      .catch((err) => {});
  }

  function onBlockedToggle() {
    if (contactView === "blocked") {
      if (interactionInfo.blocked) {
        setContacts(
          [...contacts]
            .filter(
              (f) => f.user_location_id !== interactionInfo.user_location_id
            )
            .sort((a, b) =>
              a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
            )
        );
      } else {
        setContacts(
          [
            ...contacts,
            {
              ...interactionInfo,
              id: interactionInfo.id,
              join_id: interactionInfo.id,
              name:
                interactionInfo.nickname ||
                interactionInfo.name ||
                interactionInfo.number ||
                "Unidentified",
              description: interactionInfo.last_message
                ? new Date(interactionInfo.last_message).toLocaleString()
                : "",
            },
          ].sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
          )
        );
      }
    }
  }

  function onPinnedToggle() {
    if (contactView === "pinned") {
      if (interactionInfo.pinned) {
        setContacts(
          [...contacts]
            .filter(
              (f) => f.user_location_id !== interactionInfo.user_location_id
            )
            .sort((a, b) =>
              a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
            )
        );
      } else {
        setContacts(
          [
            ...contacts,
            {
              ...interactionInfo,
              id: interactionInfo.id,
              join_id: interactionInfo.id,
              name:
                interactionInfo.nickname ||
                interactionInfo.name ||
                interactionInfo.number ||
                "Unidentified",
              description: interactionInfo.last_message
                ? new Date(interactionInfo.last_message).toLocaleString()
                : "",
            },
          ].sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
          )
        );
      }
    }
  }

  if (!user && !interactionInfo) return null;

  return (
    <div>
      {manager && (
        <Box mb="sm">
          <ChatHeader
            interactionId={id}
            name={interactionInfo.name}
            nickname={interactionInfo.nickname}
            phoneId={interactionInfo.phone_number_id}
            pinned={interactionInfo.pinned}
            phoneNumber={interactionInfo.number}
            blocked={interactionInfo.blocked}
            onSearchToggle={() => setSearching(!searching)}
            onUpdate={() => {
              onMessageSend();
              fetchData();
            }}
            onBlockedToggle={onBlockedToggle}
            onPinnedToggle={onPinnedToggle}
          />
        </Box>
      )}
      {/* {manager && (
        <>
          <Flex gap="xs">
            {contextFilters
              .filter((f) => (user ? f.value !== "rating" : true))
              .map((b, i) => (
                <Button
                  size="xs"
                  key={i}
                  variant={filters.context === b.value ? "light" : "subtle"}
                  onClick={() =>
                    setFilters({
                      ...filters,
                      context: b.value,
                    })
                  }
                >
                  {b.text}
                </Button>
              ))}
          </Flex>
          <Divider mt="sm" opacity={0.5} />
        </>
      )} */}
      <Divider mt="sm" opacity={0.1} />
      {searching ? (
        <Box mt="sm">
          <SearchWrapper>
            <div className="main__chat">
              <MessageSearch
                reqData={{
                  user_location_id: id,
                }}
              />
            </div>
          </SearchWrapper>
        </Box>
      ) : (
        <>
          {(interactionInfo || user) && (
            <Chat
              contextFilter={filters.context}
              interactionId={id}
              onMessageSend={() => {
                onMessageSend();
              }}
              title={!user ? interactionInfo.nickname : ""}
            />
          )}
        </>
      )}
    </div>
  );
}

const SearchWrapper = styled.div`
  display: grid;
  grid-template-rows: 2.5rem 5 3rem;
  height: 100%;
  width: 100%;

  .main__chat {
    height: calc(100vh - 9rem);
    overflow-y: auto;
    /* display: flex; */
    width: 100%;
    flex-grow: 1;
    /* flex-direction: column-reverse; */
    /* display: flex;
    justify-content: flex-end;
    align-items: flex-end; */
    /* padding: 1rem; */
  }
`;
