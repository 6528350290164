import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Button, Group, Divider, Select, Box, Text } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { IconCheck } from "@tabler/icons-react";

import { KeywordForm } from "@components/Keyword";

import entityColor from "@util/entityColor";
import entityIcon from "@util/entityIcon";

export default function WizardKeyword({
  locationId,
  organizationId,
  selectedEffortId,
  isWizard,
  maxEndDate,
}) {
  const [options, setOptions] = useState([]);
  const [allOptions, setAllOptions] = useState([]);
  const [effortId, setEffortId] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [contestId, setContestId] = useState("");
  const [keyword, setKeyword] = useState(null);
  const [keywordActive, setKeywordActive] = useState(false);

  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (selectedEffortId) {
      setEffortId(selectedEffortId);
    }
  }, []);

  useEffect(() => {
    if (selectedEffortId) return;
    fetchOptions();
  }, [searchValue]);

  function fetchOptions() {
    const cancel = cancelRef.current;
    if (cancel) cancel();

    const req = {
      engagement: "all",
      award: "all",
      status: "entire",
      variety: "sweepstakes",
      page: 0,
      page_size: 10,
      search_value: "",
    };

    if (searchValue) req.search_value = searchValue;
    if (locationId) req.location_id = locationId;
    if (organizationId) req.organization_id = organizationId;

    axios
      .post(`/wizard-grouped-efforts/`, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        let all = [];
        const groupedOptions = data.response.map((m) => {
          all.push(
            ...m.items.map((mm) => ({
              label: mm.name,
              value: mm.id,
              end_date: mm.end_date,
            }))
          );
          return {
            group: m.group,
            items: m.items.map((mm) => ({
              label: mm.name,
              value: mm.id,
              end_date: mm.end_date,
            })),
          };
        });
        setOptions(groupedOptions);
        setAllOptions(all);
      })
      .catch((err) => {
        setOptions([]);
        setAllOptions([]);
      });
  }

  function onKeywordActivate() {
    const req = {
      id: keyword.id,
      status: 2,
    };

    axios
      .post(`/keywords/${keyword.id}/set-status/`, req)
      .then(() => {
        setKeywordActive(true);
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  if (keyword) {
    return (
      <Box>
        {isWizard && !selectedEffortId && <Divider mt="lg" mb="lg" />}
        <Text align="center" size="xl">
          <b>{keyword.name}</b> keyword created!
        </Text>
        <Group justify="center">
          {keywordActive ? (
            <Group mt="xs">
              <IconCheck color="var(--mantine-color-green-5)" size={16} />
              <Text size="sm">Keyword active</Text>
            </Group>
          ) : (
            <Button onClick={onKeywordActivate} mt="xs" size="xs" color="green">
              Turn keyword on
            </Button>
          )}
        </Group>
        <Divider mt="lg" mb="lg" />
        <Box>
          <Text align="center" mb="sm" size="xs">
            Need to check anything out?
          </Text>
          <Group justify="center">
            <Button
              color={entityColor.keyword}
              size="xs"
              component={Link}
              to={`/keywords/${keyword.id}`}
              leftSection={entityIcon.keyword(14)}
              variant="light"
            >
              Keyword
            </Button>
            {!selectedEffortId && (
              <Button
                color={entityColor.effort}
                size="xs"
                component={Link}
                to={`/efforts/${effortId}`}
                leftSection={entityIcon.effort(14)}
                variant="light"
              >
                Effort
              </Button>
            )}
            <Button
              color={entityColor.contest}
              size="xs"
              component={Link}
              to={`/contests/${contestId}`}
              leftSection={entityIcon.contest(14)}
              variant="light"
            >
              Contest
            </Button>
          </Group>
        </Box>
        <>
          <Divider mt="lg" mb="lg" />
          <Box mt="lg">
            <Text align="center" mb="lg">
              What would you like to do now?
            </Text>
            {!selectedEffortId && (
              <Box mb="sm">
                <Button
                  onClick={() => {
                    setContestId("");
                    setKeyword(null);
                    setKeywordActive(false);
                  }}
                  fullWidth
                >
                  Create keyword for the same effort
                </Button>
              </Box>
            )}
            <Box mb="sm">
              <Button
                onClick={() => {
                  setKeyword(null);
                  setKeywordActive(false);
                }}
                fullWidth
              >
                Create keyword for the same contest
              </Button>
            </Box>
            <Box mb="sm">
              <Button
                component={Link}
                to={`/keywords/${keyword.id}/contests?quickSettings=false`}
                fullWidth
              >
                Add more contests to this keyword
              </Button>
            </Box>
            <Box mb="sm">
              <Button
                onClick={() => {
                  if (!selectedEffortId) {
                    setEffortId("");
                  }
                  setContestId("");
                  setKeyword(null);
                  setSearchValue("");
                  setKeywordActive(false);
                }}
                fullWidth
              >
                Start over
              </Button>
            </Box>
          </Box>
        </>
      </Box>
    );
  }

  const selectedEffortOption = allOptions.find((f) => f.value === effortId);

  return (
    <div>
      {!selectedEffortId && (
        <Select
          data={options}
          label="Effort"
          limit={20}
          // onChange={(e) => setEffortId(e)}
          onChange={(e) => {
            setEffortId(e);
            setContestId("");
          }}
          onSearchChange={(e) => setSearchValue(e)}
          placeholder="Select one..."
          searchable
          searchValue={searchValue}
          value={effortId}
        />
      )}
      <Box>
        <ContestSelect
          disabled={!effortId}
          label="Select a contest"
          reqData={{
            campaign_effort_id: effortId,
            award: "all",
            engagement: "recurring",
            pagination: false,
            status: "entire",
            variety: 1,
            // wizard_link_existing_efforts: true,
          }}
          onChange={(e) => setContestId(e)}
          value={contestId}
        />
      </Box>
      {contestId && effortId && (
        <Box>
          <KeywordForm
            additionalReqData={{
              campaign_effort_id: effortId,
              contest_id: contestId,
            }}
            onSuccess={(keyword, keywordName) => {
              setKeyword({
                id: keyword,
                name: keywordName,
              });
            }}
            locationId={locationId}
            organizationId={organizationId}
            maxEndDate={maxEndDate}
            formInfo={{
              end_date: maxEndDate || selectedEffortOption?.end_date,
            }}
          />
        </Box>
      )}
    </div>
  );
}

const ContestSelect = ({
  label = "Select a contest",
  reqData = {},
  onChange,
  disabledIds = [],
  value,
  disabled,
}) => {
  const [contests, setContests] = useState([]);
  const [display, setDisplay] = useState(true);

  useEffect(() => {
    fetchData();
    setDisplay(false);
    setTimeout(() => {
      setDisplay(true);
    }, 1);
  }, [JSON.stringify(reqData)]);

  function fetchData() {
    if (disabled) return;
    const req = {
      ...reqData,
      // pagination: false,
    };

    axios
      .post(`/contests-added-effort/`, req)
      .then(({ data }) => {
        setContests(
          data.response
            .sort((a, b) =>
              a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
            )
            .map((m) => ({
              label: m.name,
              value: m.id,
            }))
        );
      })
      .catch((err) => {
        setContests([]);
      });
  }

  const formattedOptions = contests.map((m) => ({
    ...m,
    disabled: disabledIds.includes(m.value),
  }));

  if (!display) {
    return null;
  }

  return (
    <Select
      disabled={disabled}
      data={formattedOptions}
      label={label}
      limit={20}
      onChange={(e) => onChange(e)}
      placeholder="Select one..."
      searchable
      value={value}
    />
  );
};
